import React, { useContext, useEffect, useRef, useMemo } from 'react'

import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'
import NoDataComponent from '../NoDataComponent'

const TextWidget = () => {
    const { widgetData: widget } = useContext(WidgetDataContext)
    const textRef = useRef(null)
    const data = widget ? widget.Data : null
    const options = widget ? widget.WidgetOptions : null
    const style = useMemo(() => {
        if (options) {
            const json = JSON.parse(options)
            if (json) {
                return json.style
            }
        }
        return null
    }, [options])

    useEffect(() => {
        textRef.current.setAttribute('style', style)
    }, [style])

    if (!(data && data.length && data[0].Value)) {
        return <NoDataComponent />
    }

    return (
        <div className="textWrapper">
            <div className="textContent" ref={textRef}>
                {data[0].Value}
            </div>
        </div>
    )

}

export default TextWidget