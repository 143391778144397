import React, {
    useContext,
    useMemo,
    useCallback
} from 'react'
import { useTable, useSortBy, useFlexLayout, useFilters } from 'react-table'
import AutoSizer from 'react-virtualized-auto-sizer'
import { TableVirtuoso } from 'react-virtuoso'
import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'
import Cell from './Cell'
import { CurrentDashboardContext } from '../../../wrappers/CurrentDashboardContext'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import stringFields from '../../../../utils/constants/widgets/stringFields'

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        className="input is-small"
        placeholder={`Search ${count} records...`}
      />
    )
  }

const parseColumns = (data, valueLabel, sharedKey) => {
    const exampleRow = data[0] // get the first row of the data to extract cols from
    const columns = Object.keys(exampleRow)
        .filter((col) => col !== 'Value') // remove the value column, we'll add next w/ proper label
        .filter((col) => (sharedKey ? col !== sharedKey : true)) // if there is a shared key, we don't want it to display in the grid
        .map((col) => ({
            Header: () => <Cell value={col} type="string"/>,
            accessor: col,
            Cell: ({ cell }) => (
                <Cell
                    value={cell.value}
                    type={stringFields.includes(col) ? 'string' : null}
                />
            ),
            Filter: DefaultColumnFilter
            
        })) // map column name => {Header: <column_name>, accessor: <column_name>} format

    // all values must be in the 'Value' field of the returned data
    if (Object.keys(exampleRow).find((col) => col === 'Value')) {
        const valueColumn = {
            Header: () => (
                <Cell value={valueLabel ? valueLabel : 'Value'} type="string"/>
            ),
            accessor: 'Value',
            Cell: ({ cell }) => <Cell value={cell.value} />,
            Filter: DefaultColumnFilter
        }
        return [...columns, valueColumn]
    } else {
        return columns
    }
}


export default () => {
    const { sharedPageKey, sharedPageId, setSharedPageId } = useContext(
        CurrentDashboardContext
    )
    const { widgetData: widget } = useContext(WidgetDataContext)
    const data = widget ? widget.Data : null
    const detailKey = widget ? widget.DetailKey : null
    const columns = useMemo(
        () => parseColumns(data, widget.ValueLabel, widget.SharedKey),
        [data, widget.ValueLabel, widget.SharedKey]
    )

    const tableData = useMemo(() => data, [data])
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30, // minWidth is only used as a limit for resizing
            width: 100, // width is used for both the flex-basis and flex-grow
            maxWidth: 300, // maxWidth is only used as a limit for resizing
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        { columns, data: tableData, defaultColumn },
        useFilters,
        useSortBy,
        useFlexLayout
    )


    const onClick = useCallback(
        (row) =>
            sharedPageKey &&
            detailKey !== sharedPageKey &&
            setSharedPageId(row.original[sharedPageKey]),
        [sharedPageKey, setSharedPageId, detailKey]
    )

    return (
        <div className="gridWrapper">
            <AutoSizer defaultHeight={300} disableWidth>
                {({ height }) => {
                    return (

                        <TableVirtuoso
                            className="reactVirtuosoContainer"
                            style={{ height: height}}
                            data={rows}
                            totalCount={rows.length}
                            components={{
                                Table: ({ style, ...props }) => <table {...getTableProps()} {...props} style={{...style }} className="table is-fullwidth is-striped" />,
                                TableBody: React.forwardRef(({ style, ...props }, ref) => <tbody {...getTableBodyProps()} {...props} ref={ref} className="tbody" />),
                                TableRow: (props) => {
                                    const index = props['data-index']
                                    const row = rows[index]
                                    return (
                                        <tr
                                        {...props}
                                        {...row.getRowProps()}
                                        onClick={() => onClick(row)}
                                        className={`tr ${
                                            sharedPageKey &&
                                            sharedPageId === row.original[sharedPageKey]
                                                ? 'active'
                                                : ''
                                        } ${sharedPageKey ? 'pointer' : ''}`}
                                    />
                                )}
                            }}
                            fixedHeaderContent={() => {
                                return headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps()}
                                            >
                                                <div className="tableHeaderWrapper">
                                                    <div>
                                                        <span {...column.getSortByToggleProps(
                                                                { className: 'gridColumnHeader sticky th' }
                                                        )}>
                                                            {column.render('Header')}
                                                            <span className="gridColumnIcon">
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ? (
                                                                        <FaChevronUp
                                                                            fontSize="0.75rem"
                                                                        />
                                                                    ) : (
                                                                        <FaChevronDown
                                                                            fontSize="0.75rem"
                                                                        />
                                                                    )
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    {/* <div className="tableFilterWrapper">{column.canFilter ? column.render('Filter') : null}</div> */}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))
                                }}
                            itemContent={(index) => {
                                const row = rows[index]
                                prepareRow(row)
                                return row.cells.map((cell) => <td {...cell.getCellProps()} className="td">{cell.render('Cell')}</td>)
                            }}
                        />
                    )
                }}
            </AutoSizer>
        </div>
    )
}
