import React from 'react'
import ErrorFallback from './ErrorFallback'



export default class ErrorBoundary extends React.Component {
    state = { hasError: false, errorMessage: null, errorKey: 0 }
    
    componentDidCatch(error) {
        const message = error.toString().slice(error.toString().indexOf(":") + 2)
        this.setState((state) => ({ hasError: true, errorMessage: message, errorKey: state.errorKey + 1 }))
      }

  
    render() {
        if (this.state.hasError) {
        return ( 
        <ErrorFallback errorMessage={this.state.errorMessage} />
            )
      }
  
      return this.props.children; 
    }
  }