import React from 'react'
import ReactSelect from 'react-select'
import { Controller } from 'react-hook-form'
import { FaInfoCircle } from 'react-icons/fa'
import { DefaultTooltip as Tooltip } from '../Tooltip'

export default ({
    options,
    onChange,
    control,
    initialValue,
    name,
    disabled,
    label,
    helper,
    rules,
    errors,
    className,
    placeholder,
    value,
}) => {
    return (
        <div className={className}>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label is-horizontal reactSelect">
                        {label}
                    </label>
                </div>
                <div className="field-body is-full-width">
                    <div className="field has-addons">
                    {helper && (
                            <div className="control">
                                <button type="button" className="button is-meduim is-static no-margin">
                                    <span
                                        data-tip={`${helper}`}
                                        data-for={name}
                                        className="icon is-size-7 is-left formHelper"
                                    >
                                        <FaInfoCircle />
                                        <Tooltip id={name} place={'right'} />
                                    </span>
                                </button>
                            </div>
                        )}
                        <div className="control is-expanded">
                            <Controller
                                as={
                                    <ReactSelect
                                        className="select is-small is-multiple is-fullwidth reactSelect is-full-height"
                                        classNamePrefix="reactSelect"
                                        options={options}
                                        name={name}
                                        menuPlacement="auto"
                                        isDisabled={disabled}
                                        placeholder={placeholder}
                                    />
                                }
                                name={name}
                                control={control}
                                defaultValue={
                                    initialValue ? initialValue : null
                                }
                                key={`my_unique_select_key__${JSON.stringify(
                                    value
                                )}`}
                                value={value}
                                rules={rules}
                                onChange={onChange}
                            />
                            
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p className="help is-danger">
                    {errors && errors[name] ? errors[name].message : null}
                </p>
            </div>
        </div>
    )
}
