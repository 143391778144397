import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'

import { CurrentDashboardContext } from '../../wrappers/CurrentDashboardContext'
import { IconTooltip as Tooltip } from '../../elem/Tooltip'

export default () => {
    const { currentWidgetGroup, widgetGroups } = useContext(CurrentDashboardContext)
    const backTarget = currentWidgetGroup.ParentPageId ? widgetGroups.find(x => x.PageId === currentWidgetGroup.ParentPageId) : {Location: '', PageName: 'Dashboard List', DashboardName: ''}
    return (
        <div className="navLinkGroupWrapper">
            <Link
                className={`navLink`}
                to={`/${backTarget.DashboardName}${backTarget.Location}`}
                data-tip={backTarget.PageName}
                data-for={`back-button`}
            >
                <span className="icon">
                    <FaArrowLeft size={`1.5em`} />
                </span>
                <Tooltip id={`back-button`} />
            </Link>
        </div>
    )
}
