// palette obtained from ColorBrewer: https://colorbrewer2.org/#type=qualitative&scheme=Paired&n=12
// and mixed with material design palette https://www.materialpalette.com/colors
const qualitative = [
    '#1f78b4', // blue
    '#33a02c', // green
    '#fdbf6f', // creme
    '#a6cee3', // light blue
    '#ff7f00', // orange
    '#b2df8a', // light green
    '#b15928', // light brown
    '#cab2d6', // lavender
    '#6a3d9a', // purple
    '#3f51b5', // indigo 500
    '#009688', // teal 500
    '#ab47bc', // purple 400
    '#f44336', // red 500
    '#90a4ae', // blue grey 300
    '#673ab7', // deep purple 500
    '#00bcd4', // cyan 500
    '#e91e63', // pink 500
    '#aeea00', // lime green A700
    '#ffc107', // amber 500
    '#ff5722', // deep orange 500
    //additional colors added
    '#880e4f', //dark purple
    '#f48fb1', // light pink
    '#1b5e20', //dark green
    '#ffab40', // med orange
    '#b71c1c', //dark red
]

// palette obtained from ColorBrewer: https://colorbrewer2.org/#type=sequential&scheme=Reds&n=6
const heatMap = [
    '#fee5d9',
    '#fcbba1',
    '#fc9272',
    '#fb6a4a',
    '#de2d26',
    '#a50f15',
]
export default qualitative
export { qualitative, heatMap }
