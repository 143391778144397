import React from 'react'
import withConfig from '../../wrappers/withConfig'

const ManageUsersButton = withConfig(({config}) => {
    const { CONFIGURED_URLS } = config
    return (
        <div className="loginButtonWrapper">
            <a
                    className="button is-small"
                    target="_blank"
                    rel="opener"
                    href={CONFIGURED_URLS.manageUsers}
                >
                    Manage Users
                </a>
        </div>
    )
})

export default ManageUsersButton