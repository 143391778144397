import dayjs from 'dayjs'

const format = "MM/DD/YYYY"
const withMinuteFormat = "MM/DD/YYYY HH:mm:ss"

const dateToString = date => date ? dayjs(date).format(format).toString() : null
const stringToDate = date => date ? dayjs(date).toDate() : null

const constructDateQueryString = (minDate, maxDate) => {
    let dateFrom = null
    let dateTo = null

    // if both date components are null, return
    // an empty string
    if (!minDate && !maxDate) {
        return ""
    }

    // if there is a minDate and no maxDate,
    // just send dateFrom = minDate
    if (minDate && !maxDate) {
        dateFrom = minDate
    }

    // if there is a maxDate and no minDate,
    // just send dateTo = maxDate
    if (maxDate && !minDate) {
        dateTo = maxDate
    }

    if (minDate && maxDate) {
        dateFrom = new Date(minDate) < new Date(maxDate) ? minDate : maxDate
        dateTo = new Date(minDate) < new Date(maxDate) ? maxDate : minDate
    }

    // make the time to the end of the day on dateto
    dateTo = dayjs(maxDate).add(1, 'day').subtract(1, 'second').format(withMinuteFormat).toString()

    const dateFromQuery = dateFrom ? `dateFrom=${encodeURIComponent(dateFrom)}` : ''
    const dateToQuery = dateTo ? `dateTo=${encodeURIComponent(dateTo)}` : ''

    return [dateFromQuery, dateToQuery].join('&')
} 

export { dateToString, stringToDate, constructDateQueryString }