
import { DoubleSide } from "three"

const meshProps = {
    rotation: [-Math.PI / 2, 0, 0],
    castShadow: false
}

const meshBasicMaterialProps = {
    side: DoubleSide, 
    attach: "material", 
    transparent: true,
    depthTest: false
}

const orbitControlsProps = {
    // enableDamping: true,
    // dampingFactor: 0.05,
    rotateSpeed: 0.35,
    screenSpacePanning: true,
    maxPolarAngle: Math.PI / 2
}

const scale = { x: 1250, y: 1250, z: 1 / 500 }

export { scale, meshProps, meshBasicMaterialProps, orbitControlsProps }