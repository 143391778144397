const cols = { lg: 12, sm: 1 }
const breakpoints = { lg: 600, sm: 0 }
const rowHeight = 150
const smallWidgetHeight = 2

export default (widgets) => {
    if (!widgets){
        return {}
    }
    const layoutKeys = Object.keys(breakpoints) // [lg, md, sm, xs, xxs]
    return layoutKeys.reduce((acc, key) => {
        const layout = widgets.map((widget, idx) => {
            const { X: x, Y: y, Width: w, Height: h, WidgetId: i } = widget // get the positioning data from widget
            if (key === 'lg') {
                // use the positioning data to
                // place widgets if we're on a large enough
                // screen
                return { i: `widget-${i}`, x, y, w, h }
            } else {
                // if we're on a small screen, just make
                // all widgets on a separate row
                return { i: `widget-${i}`, x: 0, y: idx * smallWidgetHeight, w: 1, h: smallWidgetHeight }
            }
        })
        return {
            ...acc,
            [key]: layout,
        }
    }, {})
}

export { cols, breakpoints, rowHeight }
