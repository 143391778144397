import localForage from 'localforage'
const getLayout = async (pageId) => {
    return localForage.getItem(`layout:${pageId}`).then((cachedLayout) => {
        if (cachedLayout) {
            const json = JSON.parse(cachedLayout)
            if (json) {
                return json
            }
        }
        return
    })
}

const saveLayout = (pageId, layout) => {
    localForage.setItem(`layout:${pageId}`, JSON.stringify(layout))
}

const clearLayout = (pageId) => {
    localForage.removeItem(`layout:${pageId}`)
}

export { getLayout, saveLayout, clearLayout }