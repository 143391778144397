import React, { useContext } from 'react'

import { WidgetDataContext } from '../../wrappers/WidgetDataContext'

import WidgetWrapper from './WidgetWrapper'
import Number from './number/Number'
import Grid from './grid/Grid'
import BarChart from './bar/BarChart'
import LineChart from './line/LineChart'
import ThemeMap from './map/theme-map/ThemeMap'
import FeatureMap from './map/feature-map/FeatureMap'
import ThreeDimensionalMap from './map/3d-map/ThreeDimensionalMap'
import PieChart from './pie/PieChart'
import Form from './form/Form'
import ScatterChart from './scatter/ScatterChart'
import TextWidget from './text/Text'

const getWidgetComponentFromWidgetType = (widgetType) => {
    switch (widgetType) {
        case 'Number':
            return Number
        case 'Grid':
            return Grid
        case 'BarChart':
            return BarChart
        case 'LineChart':
            return LineChart
        case 'ScatterChart':
            return ScatterChart
        case 'ThemeMap':
            return ThemeMap
        case 'PieChart':
            return PieChart
        case 'Form':
            return Form
        case '2DFeatureMap':
            return FeatureMap
        case '3DFeatureMap':
            return ThreeDimensionalMap
        case 'Text':
            return TextWidget
        default:
            return 'div'
    }
}

export default () => {
    const { widgetType } = useContext(WidgetDataContext)
    const WidgetComponent = getWidgetComponentFromWidgetType(widgetType)
    return (
    <WidgetWrapper>
        {React.createElement(WidgetComponent)}
    </WidgetWrapper>
    )
}
