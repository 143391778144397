import React from 'react'
import { Link } from 'react-router-dom'

export default ({dashboard}) => (
    <div className="dashboardLinkWrapper">
        <Link to={`/${dashboard.DashboardName}`}>
            <div className="dashboardLink">
                <div className="dashboardLinkHeaderText is-size-4">
                    {dashboard.DashboardName}    
                </div>  
                <div className="dashboardLinkDescriptionText is-size-5">
                    {dashboard.DashboardDescription}
                </div>
            </div>
        </Link>
    </div>
)