import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { DefaultTooltip as Tooltip } from '../Tooltip'

const Checkbox = ({
    name,
    label,
    onChange,
    helper,
    example,
    defaultChecked,
    disabled,
    register,
    className,
}) => {
    return (
        <div className={`${className}`}>
            <div className="field is-horizontal check">
                <div className="field-label">
                    <label className="label is-horizontal">{label}</label>
                </div>
                <div className="field-body is-horizontal">
                    <div className="field is-narrow">
                        <input
                            className="checkbox"
                            type="checkbox"
                            key={name}
                            name={name}
                            ref={register}
                            onChange={onChange}
                            defaultChecked={
                                defaultChecked ? defaultChecked : null
                            }
                            disabled={disabled}
                        />
                    </div>
                    {helper && (
                        <span
                            data-tip={`${helper}<br>${example ? `<br/> ${example}` : ''}`}
                            data-for={name}
                            className="icon is-size-7 is-left"
                        >
                            <FaInfoCircle />
                            <Tooltip id={name} place={'right'} />
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Checkbox
