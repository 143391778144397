import React, { useState, useEffect, useContext } from 'react'
import Select from '../form/Select'
import toast from '../Toast'
import withConfig from '../../wrappers/withConfig'
import { APIRequestContext } from '../../wrappers/APIRequestContext'

const Databases = ({
    config,
    control,
    initialValue,
    disabled,
    value,
    onChange,
    errors,
    helper
}) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [databases, setDatabases] = useState([])
    const databaseOptions = databases.map((x) => ({ label: x, value: x }))

    useEffect(() => {
        authenticatedFetch(`${API_URL}/admin/getTargetDatabases`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setDatabases(response.data)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Get Databases:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
    }, [API_URL])

    return (
        <Select
            label="Target Database"
            name="TargetDatabase"
            value={value}
            control={control}
            options={databaseOptions}
            disabled={disabled}
            initialValue={initialValue}
            rules={{
                required: {
                    value: true,
                    message: 'This field is required.',
                },
            }}
            onChange={onChange}
            errors={errors}
            helper={helper}
        />
    )
}

export default withConfig(Databases)
