import React, { useContext, useState } from 'react'
import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'

export default () => {
    const { widgetData: widget, showLegend, setShowLegend } = useContext(
        WidgetDataContext
    )
    const [show, setShow] = useState(false)

    if (!widget) {
        return null
    }

    if (widget && widget.WidgetType.match(/Grid|Form|Number|Text/)) {
        return null
    }

    const handleClick = () => {
        setShow(!show)
        show ? setShowLegend('all') : setShowLegend('none')
    }

    return (
        <div className="legendButtons">
            <div className="legendButton">
                {!showLegend ? (
                    <div
                        className="is-size-7"
                        onClick={() => setShowLegend(true)}
                    >
                        Show Legend
                    </div>
                ) : (
                    <div
                        className="is-size-7"
                        onClick={() => setShowLegend(false)}
                    >
                        Hide Legend
                    </div>
                )}
            </div>
            {widget.WidgetType !== 'ThemeMap' ? (
                <div className="legendButton">
                    {showLegend ? (
                        <div className="is-size-7" onClick={handleClick}>
                            {show ? 'Select All' : 'Deselect All'}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    )
}
