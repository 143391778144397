import React, { useContext } from 'react'
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from 'react-icons/fa'

import { DefaultTooltip as Tooltip } from '../../Tooltip'
import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'
import { CurrentDashboardContext } from '../../../wrappers/CurrentDashboardContext'

export default () => {
    const { widgetData: widget, setLoading } = useContext(WidgetDataContext)
    const { expandedWidgetId, setExpandedWidgetId } = useContext(
        CurrentDashboardContext
    )

    const setExpandedWidget = (widgetId) => {
        setLoading(true)
        setExpandedWidgetId(widgetId)
        setTimeout(() => {
            setLoading(false)
        }, 200)
    }

    if (!widget) {
        return null
    }

    const expanded = expandedWidgetId === widget.WidgetId
    const tooltipId = `expand-${widget.WidgetId}`
    return (
        <div className="maximizeButton" style={expanded ? {top: '11vh'} : null} data-tip={expanded ? 'Collapse' : 'Expand to Full Screen'} data-for={tooltipId}>
            {expanded ? (
                <div
                    className="maximizeButtonIcon"
                    onClick={() => setExpandedWidget(null)}
                    style={{zIndex: 10 }}
                >
                    <FaCompressArrowsAlt />
                </div>
            ) : (
                <div
                    className="maximizeButtonIcon"
                    onClick={() => setExpandedWidget(widget.WidgetId)}
                >
                    <FaExpandArrowsAlt />
                </div>
            )}
            <Tooltip id={tooltipId} place={'bottom'} />
        </div>
    )
}
