import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { dateToString } from '../../dateFormatting'
dayjs.extend(quarterOfYear)

const currentDate = dateToString(dayjs().toDate())
const lastYearEnd = dateToString(dayjs().startOf('year').subtract(1, 'day').toDate())
const lastYearStart = dateToString(dayjs().startOf('year').subtract(1, 'year').toDate())
const twoYearsAgoStart = dateToString(dayjs().startOf('year').subtract(2, 'year').toDate())
const fiveYearsAgoStart = dateToString(dayjs().startOf('year').subtract(5, 'year').toDate())
const tenYearsAgoStart = dateToString(dayjs().startOf('year').subtract(10, 'year').toDate())
const currentYearStart = dateToString(dayjs().startOf('year').toDate())
const quarterStart = dateToString(dayjs().startOf('quarter').toDate())
const lastQuarterStart = dateToString(dayjs().subtract(1, 'quarter').startOf('quarter').toDate())
const lastQuarterEnd = dateToString(dayjs().subtract(1, 'quarter').endOf('quarter').toDate())
const thirtyDaysAgo = dateToString(dayjs().subtract(30, 'day').toDate())
const ninetyDaysAgo = dateToString(dayjs().subtract(90, 'day').toDate())
const oneYearAgo = dateToString(dayjs().subtract(365, 'day').toDate())


const dateOptionsColumnOne = [
    {
        minDate: lastYearStart,
        maxDate: lastYearEnd,
        label: `Last Year`,
        dateRangeString: `(${lastYearStart} - ${lastYearEnd})`,
    },
    {
        minDate: twoYearsAgoStart,
        maxDate: lastYearEnd,
        label: `Last 2 Years`,
        dateRangeString: `(${twoYearsAgoStart} - ${lastYearEnd})`,
    },
    {
        minDate: fiveYearsAgoStart,
        maxDate: lastYearEnd,
        label: `Last 5 Years`,
        dateRangeString: `(${fiveYearsAgoStart} - ${lastYearEnd})`,
    },
    {
        minDate: tenYearsAgoStart,
        maxDate: lastYearEnd,
        label: `Last 10 Years`,
        dateRangeString: `(${tenYearsAgoStart} - ${lastYearEnd})`,
    },
    {
        minDate: currentYearStart,
        maxDate: currentDate,
        label: `Year to Date`,
        dateRangeString: `(${currentYearStart} - ${currentDate})`,
    },
    {
        minDate: null,
        maxDate: currentDate,
        label: `All Time`,
    },
]

const dateOptionsColumnTwo = [
    {
        minDate: quarterStart,
        maxDate: currentDate,
        label: `This Quarter`,
        dateRangeString: `(${quarterStart} - ${currentDate})`,
    },
    {
        minDate: lastQuarterStart,
        maxDate: lastQuarterEnd,
        label: `Last Quarter`,
        dateRangeString: `(${lastQuarterStart} - ${lastQuarterEnd})`,
    },
    {
        minDate: thirtyDaysAgo,
        maxDate: currentDate,
        label: `Last 30 Days`,
        dateRangeString: `(${thirtyDaysAgo} - ${currentDate})`,
    },
    {
        minDate: ninetyDaysAgo,
        maxDate: currentDate,
        label: `Last 90 Days`,
        dateRangeString: `(${ninetyDaysAgo} - ${currentDate})`,
    },
    {
        minDate: oneYearAgo,
        maxDate: currentDate,
        label: `Last 365 Days`,
        dateRangeString: `(${oneYearAgo} - ${currentDate})`,
    },
]

const allDateOptions = [...dateOptionsColumnOne, ...dateOptionsColumnTwo]

export { allDateOptions, dateOptionsColumnOne, dateOptionsColumnTwo }