import React from 'react'

import Links from './Links'
import BackButton from './BackButton'

export default () => (
    <aside className="menu sideNavWrapper">
        <ul className="menu-list sideNavList">
            <Links />
            <BackButton />
        </ul>
    </aside>
)
