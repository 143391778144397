import React, { createContext, useCallback } from 'react'
import { useAuth } from 'oidc-react'
import toast from '../elem/Toast'

const defaultOptions = {
    cache: 'default',
    headers: {
        CacheControl: 'max-age=3600'
    }
}

const APIRequestContext = createContext(null)

const APIRequestContextProvider = ({ children }) => {
    const { userManager } = useAuth()

    const authenticatedFetch = useCallback(async (endpoint, options) => {
        const u = await userManager.getUser()
        let fetchOptions = {}
        const authorizationHeader = u ? {
            Authorization: `Bearer ${u.access_token}`,
        } : {}
        if (options) {
            fetchOptions = {
                ...defaultOptions,
                ...options,
                headers: {
                    ...defaultOptions.headers,
                    ...options.headers,
                    ...authorizationHeader
                },
            }
        } else {
            fetchOptions = {
                method: 'GET',
                ...defaultOptions,
                headers: {
                    ...defaultOptions.headers,
                    ...authorizationHeader
                },
            }
        }
        return fetch(endpoint, fetchOptions).then(res => {
            if (res.status === 401) {
                toast({
                    level: 'error',
                    message:
                        'Authentication Error: The server responded with status code 401. Please ensure the application is correctly configured for authentication via IdentityServer',
                })
            }
            return res
        })
    }, [userManager])

    return (
        <APIRequestContext.Provider
            value={{authenticatedFetch}}
        >
            {children}
        </APIRequestContext.Provider>
    )
}

export { APIRequestContext }
export default APIRequestContextProvider
