import React from 'react'

import { dateOptionsColumnOne, dateOptionsColumnTwo } from '../../../utils/constants/date/dateOptions'

export default ({
    setMinDate,
    setMaxDate,
    dateRangeLabel,
    setDateRangeLabel,
}) => {
    const onClick = (option) => {
        const { minDate, maxDate, label } = option
        setMinDate(minDate)
        setMaxDate(maxDate)
        setDateRangeLabel(label)
    }
    return (
        <div className="dateOptionsWrapper">
            <div className="columns is-mobile">
                <div className="dateOptions column">
                    {dateOptionsColumnOne.map((option, idx) => (
                        <div
                        key={`date-option-${idx}`}
                        className={`dateOptionWrapper ${
                            dateRangeLabel === option.label ? 'active' : ''
                        }`}
                        onClick={() => onClick(option)}
                    >
                        <div className="dateRangeName">
                            {option.label}
                        </div>
                        <div className="dateRangeString is-size-7">
                            {option.dateRangeString}
                        </div>
                    </div>
                    ))}
                </div>
                <div className="dateOptions column">
                    {dateOptionsColumnTwo.map((option, idx) => (
                        <div
                            key={`date-option-${idx}`}
                            className={`dateOptionWrapper ${
                                dateRangeLabel === option.label ? 'active' : ''
                            }`}
                            onClick={() => onClick(option)}
                        >
                            <div className="dateRangeName">
                                {option.label}
                            </div>
                            <div className="dateRangeString is-size-7">
                                {option.dateRangeString}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
