import React, { useState } from 'react'
import CreateForm from './CreateForm'
import { Redirect } from 'react-router-dom'
import AdminLayout from '../../../elem/admin/AdminLayout'

const CreateDashboard = () => {
    const [name, setName] = useState(null) 
    
    const closeForm = (name) => {
        setName(name)
    }

    if (name) {
        return (
            <Redirect
                to={{
                    pathname: `/admin/dashboard/${name}`,
                }}
            />
        )
    }
    return (
        <AdminLayout title={"Create Dashboard"}>
            <CreateForm closeForm={closeForm} />
        </AdminLayout>
    )
}

export default CreateDashboard
