import React from 'react'
import { FaCogs } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export default () => {
    return (
        <div className="adminLink">
            <Link to={`/admin/dashboard`}>
                <button className="button is-info is-small">
                    <span className="icon is-small">
                        <FaCogs />
                    </span>
                    <span>Admin</span>
                </button>
            </Link>
        </div>
    )
}
