const getHiddenColumns = (widget) => {
    const options = widget.WidgetOptions
    if (options && options !== '') {
        try {
            const config = JSON.parse(options)
            if (config.hiddenColumns) {
                return config.hiddenColumns
            }
        } catch {}
        return
    }
    return []
}

export default getHiddenColumns