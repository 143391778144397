import React, { useState, useContext } from 'react'
import CreateWidgetForm from './CreateWidgetForm'
import { Redirect } from 'react-router-dom'
import AdminLayout from '../../../elem/admin/AdminLayout'
import {CurrentDashboardContext} from '../../../wrappers/CurrentDashboardContext'

const CreateWidget = () => {

    const [widgetId, setWidgetId] = useState(null)
    const { currentWidgetGroup, currentDashboard, pageId, setNewObject } = useContext(CurrentDashboardContext)
    const id = Number(pageId)

    if (!currentDashboard || !Object.keys(currentWidgetGroup).length) {
        return null
    }
    
    const closeForm = (response) => {
        setWidgetId(response)
    }
    
    if (widgetId) {
        setNewObject(widgetId)
        return (
            <Redirect
                to={{
                    pathname: `/admin/dashboard/${currentDashboard.DashboardName}/page/${id}`,
                }}
            />
        )
    }
    return (
        <AdminLayout title={'Create Widget'}>
            <CreateWidgetForm closeForm={closeForm} dateFilter={currentWidgetGroup.ApplyDateFilter} page={id} back={`/admin/dashboard/${currentDashboard.DashboardName}/page/${id}`}/>
        </AdminLayout>
    )
}

export default CreateWidget