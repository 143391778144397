import React, { useState, useEffect, useContext } from 'react'
import toast from '../../../elem/Toast'
import { useForm } from 'react-hook-form'
import { FaEdit, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import Input from '../../../elem/form/TextInput'
import withConfig from '../../../wrappers/withConfig'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'

const EditForm = ({ config, dashboard, closeForm }) => {
    const [state, setState] = useState(dashboard)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { register, handleSubmit, errors } = useForm()
    const [editing, setEditing] = useState(false)
    const formClass = editing ? 'button is-info is-small' : 'hidden'
    const textClass = editing ? 'hidden' : 'button is-info is-small'
    const { API_URL } = config

    useEffect(() => {
        setState(dashboard)
    }, [dashboard])  


    const PUT = (dashboard) => {
        return {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(dashboard),
        }
    }

    const editDashboard = (dashboard) => {

        authenticatedFetch(
            `${API_URL}/admin/dashboard/edit/${dashboard.DashboardId}`,
            PUT(dashboard)
        )
            .then(async (response) => {
                if (response.ok) {
                    return 
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Edit Dashboard:' +
                        (e.message ? e.message : 'Dashboard edit failed'),
                })
            })
            .finally(() => {
                setState(dashboard)
                closeForm(dashboard.DashboardName)
                setEditing(false)
            })
    }

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }
    const handleClick = () => {
        setState(dashboard)
        setEditing(false)
    }

    const trimData = (data) => {
        Object.keys(data).map((key) => typeof data[key] === "string" ? data[key] = data[key].replace(/\s\s+/g, ' ').trim() : data[key])
        return data
    }
    
    const onSubmit = (data) => {
        editDashboard({...dashboard, ...trimData(data)})
    }

    return (
        <>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="buttons">
                    <button
                        className={textClass}
                        type="button"
                        onClick={() => setEditing(true)}
                    >
                        <FaEdit />
                    </button>
                    <button
                        type="button"
                        onClick={handleClick}
                        className={formClass}
                    >
                        <FaTimesCircle />
                    </button>
                    <button type="submit" className={formClass}>
                        <FaCheckCircle />
                    </button>
                </div>
                <div className="formContent">
                    <Input
                        label="Dashboard Name"
                        name="DashboardName"
                        register={register}
                        registerParams={{
                            required: {
                                value: true,
                                message: 'This field is required.',
                            },
                            maxLength: {
                                value: 200,
                                message:
                                    'The name may not exceed 200 characters.',
                            },
                            pattern: {
                                value: /^([a-zA-Z]+\s)*[0-9a-zA-Z\sd-]+$/,
                                message:
                                    'The name may not contain special characters besides space;-',
                            },
                        }}
                        errors={errors}
                        defaultValue={state.DashboardName}
                        onChange={handleChange}
                        active={editing}
                    />
                    <Input
                        label="Dashboard Description"
                        name="DashboardDescription"
                        register={register}
                        registerParams={{
                            required: {
                                value: true,
                                message: 'This field is required.',
                            },
                        }}
                        errors={errors}
                        defaultValue={state.DashboardDescription}
                        onChange={handleChange}
                        active={editing}
                    />
                </div>
            </form>
        </>
    )
}

export default withConfig(EditForm)
