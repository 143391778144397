import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { CurrentDashboardContext } from '../../wrappers/CurrentDashboardContext'
import { IconTooltip as Tooltip } from '../../elem/Tooltip'

export default () => {
    const { currentWidgetGroup, widgetGroups } = useContext(CurrentDashboardContext)
    return (
        <div className="navLinkGroupWrapper">
            {widgetGroups.filter(target => !target.ParentPageId).map((target, idx) => {
                const isActive = target.Location === currentWidgetGroup.Location || target.PageId === currentWidgetGroup.ParentPageId
                const tooltipId = `nav-${target.PageId}`
                return (
                    <Link
                        key={`navLink-${idx}`}
                        className={`navLink ${isActive ? 'active' : ''}`}
                        to={`/${target.DashboardName}${target.Location}`}
                        data-tip={target.PageName}
                        data-for={tooltipId}
                    >
                        <span className="icon">
                            <i className={`fas fa-${target.Icon} navIcon`}></i>
                        </span>
                        <Tooltip id={tooltipId} />
                    </Link>
                )
            })}
        </div>
    )
}
