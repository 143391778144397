import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import CurrentDashboardContextProvider from './components/wrappers/CurrentDashboardContext'
import DateContextProvider from './components/wrappers/DateContext'
import DashboardContextProvider from './components/wrappers/DashboardContext'
import LandingPage from './components/features/landing-page/LandingPage'
import AdminDashboard from './components/features/admin/AdminDashboard'
import CreateDashboard from './components/features/admin/dashboard/CreateDashboard'
import EditDashboard from './components/features/admin/dashboard/EditDashboard'
import CreatePage from './components/features/admin/page/CreatePage'
import EditPage from './components/features/admin/page/EditPage'
import Header from './components/elem/Header'
import SideNav from './components/features/navigation/SideNav'
import TopBar from './components/elem/TopBar'
import DateSelector from './components/elem/date/DateSelector'
import DashboardPanel from './components/features/panel/DashboardPanel'
import { Toast as ToastContainer } from './components/elem/Toast'
import DeleteItem from './components/features/admin/DeleteItem'
import CreateWidget from './components/features/admin/widget/CreateWidget'
import EditWidget from './components/features/admin/widget/EditWidget'
import PageLayoutEditor from './components/features/admin/widget/PageLayoutEditor'
import ErrorBoundary from './components/features/errors/ErrorBoundary'
import PageNotFound from './components/features/errors/PageNotFound'
import ColorContextProvider from './components/wrappers/ColorContext'
import UserContextProvider from './components/wrappers/UserContext'
import APIRequestContextProvider from './components/wrappers/APIRequestContext'
import CallbackComponent from './components/features/user/CallbackComponent'
import urls from './utils/constants/urls'
import LoginPage from './components/features/user/LoginPage'
import RestrictedAccess from './components/features/user/RestrictedAccess'

export default () => (
    <React.Fragment>
        <Header />
        <div className="pageContent">
            <Router>
                <ErrorBoundary>
                    <UserContextProvider>
                        <APIRequestContextProvider>
                            <Switch>
                                <Route exact path={urls.userCallback}>
                                    <CallbackComponent />
                                </Route>
                                <Route exact path={urls.login}>
                                    <LoginPage />
                                </Route>
                                <Route exact path="/admin/dashboard">
                                    <RestrictedAccess
                                        allowedRoles={['Administrator']}
                                    >
                                        <DashboardContextProvider>
                                            <AdminDashboard />
                                        </DashboardContextProvider>
                                    </RestrictedAccess>
                                </Route>
                                <Route
                                    path="/delete"
                                    render={(props) => (
                                        <RestrictedAccess
                                            allowedRoles={['Administrator']}
                                        >
                                            <DeleteItem {...props} />
                                        </RestrictedAccess>
                                    )}
                                />
                                <Route path="/admin/dashboard/create">
                                    <RestrictedAccess
                                        allowedRoles={['Administrator']}
                                    >
                                        <CreateDashboard />
                                    </RestrictedAccess>
                                </Route>
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName"
                                    render={(props) => (
                                        <RestrictedAccess
                                            allowedRoles={['Administrator']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <EditDashboard {...props} />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    )}
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/create"
                                    render={(props) => (
                                        <RestrictedAccess
                                            allowedRoles={['Administrator']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <CreatePage {...props} />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    )}
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/:pageId"
                                    render={(props) => (
                                        <RestrictedAccess
                                            allowedRoles={['Administrator']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <EditPage {...props} />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    )}
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/:pageId/layout"
                                    render={(props) => (
                                        <RestrictedAccess
                                            allowedRoles={['Administrator']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <DateContextProvider>
                                                    <ColorContextProvider>
                                                        <PageLayoutEditor
                                                            {...props}
                                                        />
                                                        <TopBar />
                                                        <DateSelector />
                                                    </ColorContextProvider>
                                                </DateContextProvider>
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    )}
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/:pageId/widget/create"
                                    render={(props) => (
                                        <RestrictedAccess
                                            allowedRoles={['Administrator']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <CreateWidget {...props} />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    )}
                                />
                                <Route
                                    exact
                                    path="/admin/dashboard/:dashboardName/page/:pageId/widget/:widgetId"
                                    render={(props) => (
                                        <RestrictedAccess
                                            allowedRoles={['Administrator']}
                                        >
                                            <CurrentDashboardContextProvider>
                                                <EditWidget {...props} />
                                            </CurrentDashboardContextProvider>
                                        </RestrictedAccess>
                                    )}
                                />
                                <Route
                                    exact
                                    path={[
                                        '/:dashboardName',
                                        '/:dashboardName/:pageName',
                                        '/:dashboardName/:pageName/:detailKey',
                                    ]}
                                >
                                    <RestrictedAccess
                                        allowedRoles={[
                                            'Administrator',
                                            'Dashboard',
                                        ]}
                                    >
                                        <CurrentDashboardContextProvider>
                                            <DateContextProvider>
                                                <SideNav />
                                                <TopBar />
                                                <DateSelector />
                                                <ColorContextProvider>
                                                    <DashboardPanel />
                                                </ColorContextProvider>
                                            </DateContextProvider>
                                        </CurrentDashboardContextProvider>
                                    </RestrictedAccess>
                                </Route>
                                <Route exact path="/">
                                    <RestrictedAccess
                                        allowedRoles={[
                                            'Administrator',
                                            'Dashboard',
                                        ]}
                                    >
                                        <DashboardContextProvider>
                                            <LandingPage />
                                        </DashboardContextProvider>
                                    </RestrictedAccess>
                                </Route>
                                <Route component={PageNotFound} />
                            </Switch>
                        </APIRequestContextProvider>
                    </UserContextProvider>
                </ErrorBoundary>
            </Router>
        </div>
        <ToastContainer />
    </React.Fragment>
)
