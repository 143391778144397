import React from 'react'
import wellknown from 'wellknown'
import { Vector3 } from 'three'
import delaunay from 'delaunay-fast'

import colors from '../../../../../utils/colors/chartPalette'
import { Line, Polygon, Mesh } from './Shapes'

const getShapeComponent = layerType => {
    switch (layerType) {
        case 'LineString':
            return Line
        case 'Polygon':
            return Polygon
        case 'Mesh':
            return Mesh
        default:
            return 
    }
}

const prepareData = (data, options, sharedPageKey) => {
    // parse the wkt returned w/ each 3d feature
    const transformedData = data.filter(d => !!d.WKT).map(d => {
        let additionalData = {}
        try {
            additionalData = JSON.parse(d.Data)
        } catch (e) {
            console.log('unable to parse feature data from point:', d)
        }
        return {...d, geometry: wellknown.parse(d.WKT), data: additionalData}
    })

    // point data needs to be constructed into a polygon 
    const pointData = transformedData.filter(x => x.geometry.type === 'Point')
    const allPointLayerNames = pointData.map(x => {
        const layerOptions = options.find(option => option.layerName === x.LayerName)
        const layerNameField = layerOptions ? layerOptions.nameField : null
        return x.data[layerNameField]
    })
    const pointLayerNames = [...new Set(allPointLayerNames)]

    const pointsToMesh = pointLayerNames.reduce((acc, layerName, idx) => {
        const associatedData = pointData.filter(x => {
            const layerOptions = options.find(option => option.layerName === x.LayerName)
            const layerNameField = layerOptions ? layerOptions.nameField : null
            return x.data[layerNameField] === layerName
        })
        const geometryData = {
            type: 'Mesh',
            coordinates: [associatedData.map(x => x.geometry.coordinates)]
        }
        const exampleRow = associatedData[0]
        const layerOptions = options.find(option => option.layerName === exampleRow.LayerName)
        const layerNameField = layerOptions ? layerOptions.nameField : null
        return [
            ...acc,
            {
                LayerName: exampleRow.LayerName,
                geometry: geometryData,
                data: {
                    Type: exampleRow.LayerName,
                    [layerNameField]: layerName,
                    style: {
                        color: colors[idx % colors.length] 
                    }
                },
                [sharedPageKey]: exampleRow[sharedPageKey],
            }
        ]
    }, [])

    const parsedData = [
        ...transformedData.filter(x => x.geometry.type !== 'Point'),
        ...pointsToMesh
    ]

    // get the unique layernames as a set from the data
    const layerNames = [...new Set(parsedData.map(x => x.LayerName))]

    // collect metadata + associated feature set for each layer
    const layerData = layerNames.map((layerName, idx) => {
        const associatedData = parsedData.filter(x => x.LayerName === layerName)
        const associatedStyle = options.find(x => x.layerName === layerName)
        const exampleRow = associatedData[0]
        return {
            layerName: exampleRow.LayerName,
            layerType: exampleRow.geometry.type,
            layerIdx: idx,
            data: associatedData,
            [sharedPageKey]: sharedPageKey ? exampleRow[sharedPageKey] : null,
            style: associatedStyle ? associatedStyle.style : null
        }
    })
    return layerData
}

const getFeatures = (camera, gl, layerData, setTooltipState) => {
    // obtain the camera positioning:
    // this is the vector representing the top of the UIC permit well and extended a bit
    let centerPoint = new Vector3(0, 0, 0)
    if (layerData.find(x => x.layerType === 'LineString')) {
        const allCoords = layerData
                            .filter(x => x.layerType === 'LineString')
                            .reduce((acc, d) => {
                                return [...acc, ...d.data.map(pt => {
                                    return pt.geometry.coordinates[0]
                                })]
                            }, [])
        const totalLineStrings = allCoords.length
        const centerCoords = allCoords.reduce((acc, curr) => {
            return [
                acc[0] + (curr[0] / totalLineStrings),
                acc[1] + (curr[1] / totalLineStrings),
                acc[2] + (curr[2] ? curr[2] / totalLineStrings : 0)
            ]
        }, [0, 0, 0])
        centerPoint = new Vector3(...centerCoords)
        const lookAt = camera.position.applyAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2).multiplyScalar(1.5)
        camera.position.set(lookAt.x, lookAt.y, lookAt.z)
    }

    // tie the layerData to three.js components
    const features = layerData.reduce((acc, layer) => {
        
        // get the component based on wkt layer type (eg polygon, linestring, point)
        const ShapeComponent = getShapeComponent(layer.layerType)

        if (typeof ShapeComponent === 'undefined') {
            return acc
        } else {
            const layerFeatures = layer.data.map((feature, idx) => {

                // parse the feature.Data field
                // into json, if it exists
                let parsedData = {}
                let tooltipData = {}
                let styleData = {}
                if (feature.data) {
                    try {
                        parsedData = feature.data
                        // remove the style object from the parsed data, if it exists
                        tooltipData = Object.keys(parsedData).reduce((acc, curr) => {
                            if (curr !== "style") {
                                return { ...acc, [curr]: parsedData[curr]}
                            }
                            return acc
                        }, {})

                        // extract the style object if it exists
                        styleData = parsedData.style ? parsedData.style : {}
                    } catch (e) {
                        console.log('unable to parse feature data from feature:', feature)
                    }
                }

                // add attributes to the feature
                feature.tooltipData = tooltipData
                feature.styleData = styleData
                feature.layerName = layer.layerName
                feature.layerType = layer.layerType

                // return a react component that matches wkt type,
                // with props
                return React.createElement(ShapeComponent, {
                    ...layer,
                    feature,
                    key: `${layer.layerName}-${layer.layerType}-${idx}`,
                    centerPoint,
                    camera,
                    gl,
                    setTooltipState
                })
            })
            return [...acc, ...layerFeatures ]
        }
    }, [])
    return features
}


const getLegendDataFromLayers = (layerData) => {
    return layerData.map(layer => {
        const { layerName, style } = layer
        return {
            layerName,
            fillColor: style && style.color ? style.color : 'rgb(55,55,55)',
            strokeColor: style && style.color ? style.color : 'rgb(55,55,55)',
            visible: true
        }
    })
}

const generateMeshFromPointCloud = (coordinates, centerPoint, scale) => {
    const scaledCoords = coordinates.map(pt => ([
        (pt[0] - centerPoint.x) * scale.x,
        (pt[1] - centerPoint.y) * scale.y,
        (pt[2] - centerPoint.z) * scale.z
    ]))

    // lop off the 3d aspect of the point
    const planeRepresentation = scaledCoords.map(pt => ([
        pt[0], pt[1]
    ]))


    // convert the plane representation to delaunay triangle 
    // representation
    const cells = delaunay.triangulate(planeRepresentation)

    // create triangles from the cells
    const numTriangles = cells.length ? cells.length / 3 : 0
    
    let triangles = []
    for (let i = 0; i < numTriangles; i++) {
        triangles.push(new Vector3(...scaledCoords[cells[(i*3)]]))
        triangles.push(new Vector3(...scaledCoords[cells[(i*3) + 1]]))
        triangles.push(new Vector3(...scaledCoords[cells[(i*3) + 2]]))
    }

    return triangles
}

export { getFeatures, getShapeComponent, prepareData, getLegendDataFromLayers, generateMeshFromPointCloud }