import React, { createContext, useState, useEffect, useContext, useMemo } from 'react'

import {
    constructDateQueryString,
} from '../../utils/dateFormatting'
import { CurrentDashboardContext } from './CurrentDashboardContext'
import { allDateOptions } from '../../utils/constants/date/dateOptions'

const DateContext = createContext(null)

const DateContextProvider = ({ children }) => {
    const { defaultDateLabel } = useContext(CurrentDashboardContext) 
    const [selectorVisible, toggleSelectorVisible] = useState(false)
    const defaultDate = useMemo(() => allDateOptions.find(x => x.label === defaultDateLabel), [defaultDateLabel])
    const [date, setDate] = useState({
        minDate: defaultDate.minDate,
        maxDate: defaultDate.maxDate
    })

    const [dateQueryString, setDateQueryString] = useState(
        constructDateQueryString(date.minDate, date.maxDate)
    )

    const [dateRangeLabel, setDateRangeLabel] = useState(defaultDateLabel) // default date range is last year

    // update the date + date range on updates to defaultDateLabel (coincides with page changes to non-null default date range values)
    useEffect(() => {
        setDate(defaultDate)
        setDateQueryString(constructDateQueryString(defaultDate.minDate, defaultDate.maxDate))
    }, [defaultDate])
    useEffect(() => setDateRangeLabel(defaultDateLabel), [defaultDateLabel])
    
    // on date changes, construct the date
    // query parameter clause that gets appended to
    // widget fetch urls
    useEffect(() => {
        const newDateString = constructDateQueryString(
            date.minDate,
            date.maxDate
        )
        setDateQueryString(newDateString)
    }, [date])

    return (
        <DateContext.Provider
            value={{
                selectorVisible,
                toggleSelectorVisible,
                date,
                setDate,
                dateQueryString,
                dateRangeLabel,
                setDateRangeLabel,
            }}
        >
            {children}
        </DateContext.Provider>
    )
}

export { DateContext }
export default DateContextProvider
