import React, { useState, useEffect, useRef } from 'react'

const getTooltipPositionProps = (pixel, mapWidth, bottomPixel) => {
    // horizontal edge detection
    const tooltipWidth = 200
    const pixelX = pixel[0]
    const tooltipRightPxValue = pixelX + tooltipWidth
    const exceedsRightEdge = tooltipRightPxValue > mapWidth
    const horizontalProps = exceedsRightEdge
        ? { right: mapWidth - pixel[0] + 'px' }
        : { left: pixel[0] + 'px' }

    // vertical edge detection
    const tooltipHeight = 100
    const tooltipYTopOffset = 50
    const tooltipYBottomOffset = tooltipHeight - tooltipYTopOffset
    const pixelY = pixel[1]
    
    // top edge
    const topDelta = pixelY - tooltipYTopOffset
    const exceedsTopEdge = topDelta < 0

    // bottom edge
    const bottomDelta = pixelY - 10 + tooltipYBottomOffset - bottomPixel
    const exceedsBottomEdge = bottomDelta > 0
    
    const verticalProps = exceedsTopEdge
        ? { top: pixel[1] - (tooltipYTopOffset + topDelta) + 10 + 'px' }
        : exceedsBottomEdge ? 
            { top: pixel[1] - tooltipYTopOffset - bottomDelta } 
            : { top: pixel[1] - tooltipYTopOffset + 'px' }

    const styleProps = {
        ...horizontalProps,
        ...verticalProps,
    }
    return styleProps
}

export default ({ left, top, right, bottom, feature, tooltipData }) => {
    const tooltipRef = useRef(null)
    const featureRef = useRef(feature)
    // display logic 
    const [display, setDisplay] = useState('none')

    // when a new feature is highlighted,
    // set display block
    useEffect(() => {
        if (feature) {
            setDisplay('block')
            featureRef.current = feature
        }
        else {
            setDisplay('none')
            featureRef.current = null
        }
    }, [feature])

    // when the tooltip loads,
    // setup mouseenter and mouseleave
    // functions to ensure mouseover
    // prevents tooltip from hiding
    useEffect(() => {
        if (tooltipRef.current) {
            tooltipRef.current.onmouseenter = () => setDisplay('block')
            tooltipRef.current.onmouseleave = () => { 
                if (!featureRef.current) {
                    setDisplay('none')
                }}
        }
    }, [featureRef])

    if (!tooltipData || !tooltipData.length) {
        return null
    }

    return (
        <div
            className="mapTooltipWrapper"
            style={{
                left,
                right,
                bottom,
                top,
                display,
            }}
            ref={tooltipRef}
        >
            <div className="mapTooltipContent">
                {tooltipData.filter(x => x.field !== "PKey").map((d, idx) => (
                    <div key={`tooltipData-${idx}`} className="mapTooltipRowWrapper">
                        <div className="mapTooltipLabel">
                            {`${d.field} :`}
                        </div>
                        <div className="mapTooltipValue">
                            {`${d.value}`}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export { getTooltipPositionProps }