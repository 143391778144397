import React from 'react'
import { IconContext } from 'react-icons'
import { FaSpinner } from 'react-icons/fa'

const Spinner = ({ size }) => {
    return (
        <div className="loadingSpinnerWrapper">
            <IconContext.Provider
                value={{ className: 'fa-pulse', size }}
            >
                <FaSpinner />
            </IconContext.Provider>
        </div>
    )
}
const LoadingSpinner = () => <Spinner size="4em" />

export default LoadingSpinner

export { Spinner }
