import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { DefaultTooltip as Tooltip } from '../Tooltip'

export default ({
    label,
    name,
    register,
    registerParams,
    onChange,
    helper,
    example,
    value,
    active,
    errors,
    defaultValue,
    className,
}) => {
    const text = !value ? defaultValue : value

    return (
        <div className={`${className}`}>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label is-horizontal">{label}</label>
                </div>
                <div className="field-body">
                    <div className="field has-addons">
                        {active !== false && helper && (
                            <div className="control">
                                <button className="button is-static no-margin">
                                    <span
                                        data-tip={`${helper}<br>${
                                            example ? `<br/> Example: ${example}` : ''
                                        }`}
                                        data-for={name}
                                        className="icon is-size-7 formHelper has-tooltip-multiline"
                                    >
                                        <FaInfoCircle />
                                        <Tooltip
                                            id={name}
                                            className={'helper'}
                                            place={'right'}
                                        ></Tooltip>
                                    </span>
                                </button>
                            </div>
                        )}
                        <div className="control is-expanded has-icons-right">
                            {active === false ? (
                                <p className="text">{text}</p>
                            ) : (
                                <input
                                    className="input"
                                    type="text"
                                    name={name}
                                    value={value}
                                    defaultValue={defaultValue}
                                    ref={
                                        register
                                            ? register(registerParams)
                                            : null
                                    }
                                    onChange={onChange}
                                    active={active}
                                />
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>

            <p className="help is-danger">
                {errors[name] ? errors[name].message : null}
                {errors[name] && errors[name].type === 'validate'
                    ? 'Must be valid JSON'
                    : null}
            </p>
        </div>
    )
}
