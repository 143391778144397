import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { CurrentDashboardContext } from '../wrappers/CurrentDashboardContext'

export default ({ name }) => {
    const { currentWidgetGroup, widgetGroups } = useContext(
        CurrentDashboardContext
    )

    const [isActive, setIsActive] = useState(false)


    return (
        <div className={`dropdown ${
            isActive ? 'is-active' : ''
        }`}>
            <div className="dropdown-trigger">
                <p
                    className="is-small"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    onClick={() => setIsActive(!isActive)}
                >
                    <span>{name}</span>
                    <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                </p>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu" style={{zIndex: 5}}>
                <div className="dropdown-content">
                    {widgetGroups
                        .filter((target) => !target.ParentPageId)
                        .map((target, idx) => {
                            const active =
                                target.Location ===
                                    currentWidgetGroup.Location ||
                                target.PageId ===
                                    currentWidgetGroup.ParentPageId
                            return (
                                <Link
                                    key={`navLink-${idx}`}
                                    className={`dropdown-item ${
                                        active ? 'active' : ''
                                    }`}
                                    to={`/${target.DashboardName}${target.Location}`}
                                    data-tip={target.PageName}
                                    onClick={()=> setIsActive(!isActive)}
                                >
                                    {target.PageName}
                                </Link>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
