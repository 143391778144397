import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Label,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine,
    Brush
} from 'recharts'
import dayjs from 'dayjs'

import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'

import { formatNumber } from '../../../../utils/numberFormatting'
import { dateToString } from '../../../../utils/dateFormatting'
import getHiddenColumns from '../../../../utils/widgets/getHiddenColumns'
import { ColorContext } from '../../../wrappers/ColorContext'

const MAX_TICKS = 150

const CustomLegend = ({ legendPayload, bold, setBold, updateHiddenState }) => {
    return (
        <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {legendPayload.map((item, index) => (
                <li
                    key={`line-legend-${index}`}
                    className={`recharts-legend-item legend-item-${index}`}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                >
                    <svg
                        className="recharts-surface"
                        width="14"
                        height="14"
                        viewBox="0 0 32 32"
                        version="1.1"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginRight: '4px',
                        }}
                        onClick={() => updateHiddenState(item, index)}
                    >
                        <path
                            stroke="none"
                            fill={item.color}
                            d="M0,4h32v24h-32z"
                            className="recharts-legend-icon"
                        ></path>
                    </svg>
                    <span
                        className="recharts-legend-item-text"
                        style={
                            bold === item.value
                                ? {
                                      textDecoration: 'underline',
                                      textDecorationColor: item.color,
                                  }
                                : null
                        }
                        onClick={() =>
                            bold && bold === item.value
                                ? setBold(null)
                                : setBold(item.value)
                        }
                    >
                        {item.value}
                    </span>
                </li>
            ))}
        </ul>
    )
}

const getSeriesFromData = (data, hiddenColumns) => {
    // if there is not a 'Value' column, then the series
    // values are stored in a column for each series name
    const seriesAreDistinctColumns =
        typeof Object.keys(data[0]).find((x) => x.includes('Value')) ===
        'undefined'

    if (seriesAreDistinctColumns) {
        // get the line chart series names, eg in { Date: <date>, Series A: <value>, Series B: <district>}
        // => groupKeys == ['Series A', 'Series B']
        
        // filter out Date and any hiddenColumns
        const groupKeys = data.reduce(
            (acc, curr) => [
                ...acc,
                ...Object.keys(curr).filter((x) => !x.includes('Date')).filter(x => !hiddenColumns.includes(x)),
            ],
            []
        )

        // get a list of all unique groups that are present in the data
        // => ['Series A', 'Series B']
        const uniqueGroups = [...new Set([...groupKeys])].sort()

        // return data + group names
        return [data, uniqueGroups, null]
    } else {
        // otherwise, the group key is just the column that is not Value or Date
        // eg data[0] = {'Value': 0, 'Date': '12/12/12', 'Form Type': 'Wow'}
        // => groupKey = 'Form Type'
        const groupKey = Object.keys(data[0]).find(
            (x) => !(x.includes('Date') || x.includes('Value')) && !(hiddenColumns.includes(x))
        )

        // get the set of all unique values in the column specified by groupKey
        const uniqueGroups = [...new Set(data.map((x) => x[groupKey]))].sort()

        // aggregate line chart data using unique dates. eg
        // [{Date: date1, District: 1, Value: 100}, {Date: date1, District: 2, Value: 200}]
        // turns into [{Date: date1, "1": 100, "2": 200}]
        const chartData = [...new Set(data.map((d) => d.Date))].sort().map((date) => {
            const dateEntry = { Date: date }
            return data
                .filter((x) => x.Date === date) // get entries associated w/ the current date
                .reduce((acc, curr) => {
                    const groupName = curr[groupKey]
                    const value = curr['Value']
                    return {
                        ...acc,
                        [groupName]: value,
                    }
                }, dateEntry)
        }).sort((a, b) => a.Date - b.Date)

        return [chartData, uniqueGroups, groupKey]
    }
}

const getReferenceLineData = options => {
    if (options) {
        try {
            const config = JSON.parse(options)
            const referenceData = config.reference ? config.reference : null
            return referenceData
        } catch (e) {
        }
    }
}
export default () => {
    const { widgetData: widget, showLegend } = useContext(WidgetDataContext)
    const { colorMap, updateColorMap, getAssociatedColor } = useContext(ColorContext)
    const data = useMemo(() => widget ? widget.Data : null, [widget])
    const options = useMemo(() => widget ? widget.WidgetOptions : null, [widget])
    const [legend, setLegend] = useState([])

    const referenceData = useMemo(() => getReferenceLineData(options), [options])
    const preparedData = useMemo(() => data
        .filter((d) => !!d.Date) // remove data w/out dates
        .map((d) => ({
            ...d,
            Date: dayjs(d.Date).toDate().getTime(),
        })) // transform dates to MM/DD/YYYY format
    , [data])

    const hiddenColumns = useMemo(() => getHiddenColumns(widget), [widget])
    const [chartData, uniqueGroups, groupKey] = useMemo(() => getSeriesFromData(preparedData, hiddenColumns), [preparedData, hiddenColumns])

    // create state to track hidden prop for each individual line
    const [hiddenState, setHiddenState] = useState(
        uniqueGroups.reduce((acc, curr) => ({ ...acc, [curr]: false }), {})
    )
    const [bold, setBold] = useState(null)

    useEffect(() => {
        updateColorMap(groupKey, uniqueGroups)
    }, [uniqueGroups, groupKey])

    
    useEffect(() => {
        setLegend(
            uniqueGroups.map((groupName, idx) => ({
                color: !hiddenState[groupName]
                    ? getAssociatedColor(groupName, groupKey)
                    : 'rgb(204, 204, 204)',
                type: 'line',
                dataKey: groupName,
                value: groupName,
                hide: hiddenState[groupName] ? true : false,
            })
        ))
    }, [hiddenState, colorMap])

    useEffect(() => {
        if (showLegend === 'all' && Object.values(hiddenState).includes(true)) {
            setHiddenState(
                uniqueGroups.reduce(
                    (acc, curr) => ({ ...acc, [curr]: false }),
                    {}
                )
            )
        }
        if (
            showLegend === 'none' &&
            Object.values(hiddenState).includes(false)
        ) {
            setHiddenState(
                uniqueGroups.reduce(
                    (acc, curr) => ({ ...acc, [curr]: true }),
                    {}
                )
            )
        }
    }, [showLegend])

    const colors = useMemo(
        () =>
            uniqueGroups.reduce((acc, curr, idx) => {
                return {
                    ...acc,
                    [curr]: getAssociatedColor(curr, groupKey),
                }
            }, {}),
        [uniqueGroups, groupKey, colorMap]
    )

    const updateHiddenState = (e) => {
        setHiddenState((previousState) => ({
            ...previousState,
            [e.dataKey]: !previousState[e.dataKey],
        }))
    }

    // const delay = 500
    // let click = 0

    // const handleClicks = (e) => {
    //     console.log(e, "event")
    //     click > 2 ? (click = 0) : click++
    //     setTimeout(() => {
    //         if (click === 1) {
    //             updateHiddenState(e)
    //             click = 0
    //         }
    //         if (click === 2) {
    //             bold && bold === e.dataKey ? setBold(null) : setBold(e.dataKey)
    //             click = 0
    //         }
    //     }, delay)
    // }

    const dataMax = useMemo(() => Math.max(
        ...chartData.map((entry) =>
            Math.max(
                ...Object.keys(entry).map((key) =>
                    key !== 'Date' && !hiddenState[key] ? entry[key] : 0
                )
            )
        )
    ), [chartData, hiddenState])

    const dataMin = useMemo(() => Math.min(
        ...chartData.map((entry) =>
            Math.min(
                ...Object.keys(entry).map((key) =>
                    key !== 'Date' && !hiddenState[key] ? entry[key] : Infinity
                )
            )
        )
    ), [chartData, hiddenState])

    // generate a line component for each
    // individual line
    const Lines = useMemo(() => uniqueGroups.map((groupName, idx) => (
        <Line
            type="monotone"
            dot={false}
            key={`bar-${idx}`}
            dataKey={`${groupName}`}
            stackId={'shared'}
            stroke={colors[groupName]}
            strokeWidth={bold === groupName ? 3 : 1}
            activeDot={{ r: 5 }}
            hide={hiddenState[groupName]}
        />
    )), [uniqueGroups, hiddenState, colors, bold])

    const References = useMemo(() => uniqueGroups.map((groupName, idx) => {
        const hasReference = referenceData ? !!referenceData[groupName] : false
        if (!hasReference) {
            return null
        }
        return (
            <ReferenceLine
                y={referenceData[groupName]}
                stroke={hiddenState[groupName] ? "none" : colors[groupName]}
                strokeWidth={bold === groupName ? 3 : 1}
                strokeDasharray="10 1"
                key={groupName}
            />
            )
        }
    ), [uniqueGroups, referenceData, hiddenState, colors, bold])

    return (
            <ResponsiveContainer height={'99%'} debounce={1}>
                <LineChart
                    data={chartData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 10,
                        bottom: 20,
                    }}
                >
                    <Legend
                        content={
                            <CustomLegend
                                legendPayload={legend}
                                bold={bold}
                                setBold={setBold}
                                hiddenState={hiddenState}
                                updateHiddenState={updateHiddenState}
                            />
                        }
                        verticalAlign="top"
                        wrapperStyle={{
                            display: showLegend ? Object.keys(legend).length ? 'block' : 'none' : 'none',
                            top: 1,
                            left: 0,
                            overflowX: 'hidden',
                            overflowY: 'scroll',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        dataKey="Date"
                        tickFormatter={(unixTime) => dateToString(unixTime)}
                    >
                        <Label
                            value={'Date'}
                            offset={-10}
                            position="insideBottom"
                            className="barChartXAxisLabel"
                        />
                    </XAxis>
                    <YAxis
                        tickFormatter={(value) => formatNumber(value, 'compact')}
                        domain={[dataMin, dataMax]}
                        allowDataOverflow={true}
                    >
                        <Label
                            value={widget.ValueLabel}
                            angle={-90}
                            offset={20}
                            position="insideLeft"
                            className="barChartYAxisLabel"
                        />
                    </YAxis>
                    <Tooltip
                        formatter={(value) => formatNumber(value)}
                        labelFormatter={(label) => dateToString(label)}
                        isAnimationActive={false}
                        wrapperStyle={
                            Object.values(hiddenState).filter((x) => x === false)
                                .length > 10
                                ? { fontSize: '.75rem' }
                                : { fontSize: '1rem' }
                        }
                        itemStyle={{ padding: '0px' }}
                    />
                    {Lines}
                    {References}
                    {chartData && chartData.length > MAX_TICKS && <Brush endIndex={MAX_TICKS} travellerWidth={0} dataKey={'Date'} height={20} tickFormatter={(unixTime) => dateToString(unixTime)}/> }
                </LineChart>
            </ResponsiveContainer>
    )
}
