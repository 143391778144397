import React, { useState, createContext } from 'react'
import { getTooltipPositionProps } from '../Tooltip'

const TooltipContext = createContext(null)

const TooltipContextProvider = ({children}) => {
    const [tooltipState, setTooltipState] = useState(null)

    const updateTooltipState = (tooltipData) => {
        if (tooltipData) {
            const { data, target } = tooltipData
            const pixel = [target.offsetX, target.offsetY]
            const styleProps = getTooltipPositionProps(pixel, target.scrollWidth, target.clientHeight)

            setTooltipState({
                data,
                feature: {},
                ...styleProps
            })
        } else {
            setTooltipState(prevState => ({
                ...prevState,
                data: {}
            }))
        }
    }

    return (
        <TooltipContext.Provider
            value={{tooltipState, updateTooltipState}}
        >
            {children}
        </TooltipContext.Provider>
    )
}

export { TooltipContext }
export default TooltipContextProvider