export default [
    '2DFeatureMap',
    '3DFeatureMap',
    'BarChart',
    'Form',
    'Grid',
    'LineChart',
    'ScatterChart',
    'PieChart',
    'ThemeMap'
]