import React, { createContext, useContext, useEffect, useState } from 'react'
import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'

const MapContext = createContext(null)

const MapContextProvider = ({ data, legendFunction, children }) => {
    const [legendState, setLegendState] = useState([])
    const {showLegend} = useContext(WidgetDataContext)

    useEffect(() => setLegendState(legendFunction(data)), [data, legendFunction])

    // when the legend is clicked, toggle visibility for the layer
    // associated with the legend item
    const toggleLayerVisibility = (layerName) => {
        const updatedLegendState = legendState.map((legendItem) => ({
            ...legendItem,
            visible:
                legendItem.layerName === layerName
                    ? !legendItem.visible
                    : legendItem.visible,
        }))
        setLegendState(updatedLegendState)
    }

    useEffect(()=> {
        if(showLegend === "all"){
             setLegendState(legendState.map((item) => ({...item, visible: true})))
        }
        if(showLegend === "none"){
             setLegendState(legendState.map((item) => ({...item, visible: false})))
        }
     
     }, [showLegend])
    
    return (
        <MapContext.Provider value={{ legendState, toggleLayerVisibility }}>
            {children}
        </MapContext.Provider>
    )
}

export { MapContext }
export default MapContextProvider
