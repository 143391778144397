import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { DefaultTooltip as Tooltip } from '../Tooltip'

export default ({
    label,
    name,
    register,
    registerParams,
    onChange,
    helper,
    example,
    value,
    active,
    errors,
    defaultValue,
    className,
}) => {
    const text = !value ? defaultValue : value

    return (
        <div className={`${className}`}>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label is-horizontal">
                        {label}{' '}
                        {helper && (
                            <span
                                data-tip={`${helper}<br>${
                                    example ? `<br/> Example: ${example}` : null
                                }`}
                                data-for={name}
                                className="icon is-size-7 is-left formHelper"
                            >
                                <FaInfoCircle />
                                <Tooltip id={name} place={'right'} />
                            </span>
                        )}
                    </label>
                </div>
                <div className="field-body">
                    <div className="field is-narrow">
                        {active === false ? (
                            <p className="text">{text}</p>
                        ) : (
                            <input
                                className="input number has-text-left is-size-6"
                                type="number"
                                name={name}
                                value={value}
                                defaultValue={defaultValue}
                                ref={register ? register(registerParams) : null}
                                onChange={onChange}
                                active={active}
                            />
                        )}
                    </div>
                </div>
            </div>

            <p className="help is-danger">
                {errors && errors[name] ? errors[name].message : null}
            </p>
        </div>
    )
}