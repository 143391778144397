import React, { useMemo } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import { Link } from 'react-router-dom'
import { FaEdit, FaTrashAlt, FaAngleUp, FaAngleDown } from 'react-icons/fa'


const EditPageTable = ({ widgets }) => {

    const ManageCell = ({ value, pageId, name, dashboardName }) => {
        return (
            <>
                <Link
                    to={{
                        pathname: `/admin/dashboard/${dashboardName}/page/${pageId}/widget/${value}`,
                        widgetProps: {
                            id: value,
                        },
                    }}
                >
                    <button className="button is-small is-info">
                        <FaEdit />
                    </button>
                </Link>
                <Link
                    to={{
                        pathname: `/delete`,
                        deleteProps: {
                            id: value,
                            name: name,
                            pageId: pageId,
                            dashboardName: dashboardName,
                            itemType: 'widget',
                        },
                    }}
                >
                    <button className="button is-small is-info">
                        <FaTrashAlt />
                    </button>
                </Link>
            </>
        )
    }
    const createColumns = () => [
        { Header: 'Widget Id', accessor: 'WidgetId' },
        { Header: 'Widget Name', accessor: 'WidgetName' },
        { Header: 'Widget Type', accessor: 'WidgetType' },
        {
            Header: 'Manage',
            accessor: 'WidgetId',
            id: '5',
            Cell: ({ cell, row }) => (
                <ManageCell
                    value={cell.value}
                    name={row.original.WidgetName}
                    pageId={row.original.PageId}
                    dashboardName={row.original.DashboardName}
                />
            ),
        },
    ]

    const data = widgets
    const columns = useMemo(() => createColumns(), [])
    const tableData = useMemo(() => data, [data])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: tableData,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useSortBy,
        usePagination
    )

    return (
        <div className="dashboardWrapper">
            <h4 className="title is-4">Page Widgets</h4>
            <table
                {...getTableProps()}
                className="table is-fullwidth is-striped"
                style={{
                    height: '200px',
                }}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <FaAngleUp />
                        : <FaAngleDown />
                      : ''}
                  </span>
                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    {'<'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
            </div>
        </div>
    )
}

export default EditPageTable
