import React from 'react'
// import { Link } from 'react-router-dom'
import Particles from 'react-particles-js'
import particleConfig from './particleConfig'

const ErrorFallback = ({ errorMessage }) =>  {


    return(
    <>
    <Particles className={'particlesWrapper'} params={particleConfig} />

    <section className="section">
        <div className="error">
            <h3 className="title is-4">Something went wrong...</h3>
            <p>{errorMessage}</p>
            {/* <Link
                className="button"
                // onClick={() => resetError()}
                to={{
                    pathname: `/`,
                }}
            >
                Return Home
            </Link> */}
        </div>
    </section>
    </>
)}

export default ErrorFallback
