import React, { useContext } from 'react'
import { DateContext } from '../../wrappers/DateContext'
import { FaCalendar, FaTimes } from 'react-icons/fa'

const DateDisplayComponent = ({ dateRangeLabel, date }) => {
    if (dateRangeLabel !== 'Custom') {
        return <div className="topBarDateWrapper presetRange">{dateRangeLabel}</div>
    } else {
        return (
            <>
                {date.minDate ? (
                    <div className="topBarDateWrapper">
                        <div className="label">From:</div>
                        {date.minDate}
                    </div>
                ) : null}
                {date.maxDate ? (
                    <div className="topBarDateWrapper">
                        <div className="label">To:</div>
                        {date.maxDate}
                    </div>
                ) : null}
            </>
        )
    }
}
export default () => {
    const {
        date,
        selectorVisible,
        toggleSelectorVisible,
        dateRangeLabel,
    } = useContext(DateContext)

    return (
        <div
            className="topBarDateComponentWrapper"
            onClick={() => toggleSelectorVisible(!selectorVisible)}
        >
            <div className="topBarDateToggle toggle">
                {selectorVisible ? <FaTimes /> : <FaCalendar />}
            </div>
            <DateDisplayComponent dateRangeLabel={dateRangeLabel} date={date} />
        </div>
    )
}
