const allowsOverflow = widget => {
    const widgetType = widget && widget.WidgetType ? widget.WidgetType : null
    switch (widgetType) {
        case "BarChart":
            return false
        case "LineChart":
            return false
        case "ScatterChart":
            return false
        case "PieChart":
            return false
        default:
            return true
    }
}

export { allowsOverflow }