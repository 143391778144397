import React, { useMemo } from 'react'
import { useTable, usePagination } from 'react-table'

import NoDataComponent from '../../../elem/widgets/NoDataComponent'

const createColumns = (data) => {
    const keys = data && data.length ? Object.keys(data[0]) : []
    return keys.map((key) => ({ Header: key, accessor: key }))
}

const PreviewQueryTable = ({ data }) => {
    const removeEmpty = (array) => {
        array.forEach((obj) => {
            Object.keys(obj).forEach((k) => {
                if (typeof obj[k] === 'object') {
                    obj[k] = ''
                }
            })
        })
        return array
    }

    const columns = useMemo(() => createColumns(data), [data])
    const tableData = useMemo(() => removeEmpty(data), [data])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: tableData,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        usePagination
    )

    if (!(data && data.length)) {
        return <NoDataComponent />
    }

    return (
        <div className="dashboardWrapper">
            <h4 className="title is-4">Response Data Preview</h4>
            <table
                {...getTableProps()}
                className="table is-fullwidth is-striped"
                style={{
                    height: '200px',
                }}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    type="button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    {'<'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <button
                    type="button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                >
                    {'>'}
                </button>{' '}
            </div>
        </div>
    )
}

export default PreviewQueryTable
