import React, { useContext } from 'react'

import { MapContext } from '../MapContext'
import { WidgetDataContext } from '../../../../wrappers/WidgetDataContext'

export default () => {
    const { showLegend } = useContext(WidgetDataContext)
    const { legendState, toggleLayerVisibility } = useContext(MapContext)

    return (
        <div className="mapLegendWrapper" style={{display: showLegend ? '' : 'none'}}>
            {legendState.map((legendItem, idx) => {
                const backgroundColor = legendItem.visible
                    ? legendItem.fillColor
                    : 'white'
                const border = legendItem.visible
                    ? legendItem.strokeColor
                        ? `solid 1px ${legendItem.strokeColor}`
                        : null
                    : 'solid 1px black'
                const borderRadius = legendItem.visible
                    ? legendItem.geometryType === 'LineString'
                        ? '10px'
                        : null
                    : '0px'

                return (
                    <div
                        key={`legend-item-${idx}`}
                        className="mapLegendItemWrapper"
                        onClick={() =>
                            toggleLayerVisibility(legendItem.layerName)
                        }
                    >
                        <div className="mapLegendItemColor">
                            <span
                                className="mapLegendItemSwatch"
                                style={{
                                    backgroundColor,
                                    border,
                                    borderRadius,
                                }}
                            />
                        </div>
                        <div className="mapLegendItemText">
                            {legendItem.layerName}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
