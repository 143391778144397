import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from 'react'
import {
    PieChart,
    Pie,
    Legend,
    Cell,
    Sector,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'

import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'

import { formatNumber } from '../../../../utils/numberFormatting'
import { ColorContext } from '../../../wrappers/ColorContext'

const createInitialState = (uniqueGroups) =>
    uniqueGroups.reduce(
        (acc, curr) => ({
            ...acc,
            [curr]: { hidden: false, highlight: false },
        }),
        {}
    )

const resetStateField = (displayState, field, state) =>
    Object.keys(displayState).reduce(
        (acc, curr) => ({
            ...acc,
            [curr]: { ...displayState[curr], [field]: state },
        }),
        {}
    )

const renderActiveShape = (props) => {
    const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
    } = props
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 2}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    )
}

const CustomTooltip = ({ payload, data, groupKey }) => {
    const label = payload?.[0]?.payload[groupKey]
    const value = payload?.[0]?.payload['Value']
    const total = data.map((d) => d['Value']).reduce((a, b) => a + b, 0)
    const percent = formatNumber((value / total) * 100)
    return (
        <div
            className="recharts-default-tooltip"
            style={{
                padding: '10px',
                backgroundColor: 'rgb(255, 255, 255)',
                border: '1px solid rgb(204, 204, 204)',
                whiteSpace: 'nowrap',
            }}
        >
            <span style={{ color: payload?.[0]?.payload?.fill }}>
                <b>{label ? label : 'Null'}</b>:{' '}
                {formatNumber(payload?.[0]?.payload?.Value)} ({percent}%)
            </span>
        </div>
    )
}

const CustomLegend = ({
    legendPayload,
    payload,
    activeLegendIndex,
    toggleDisplayState,
}) => {
    const getLegendValue = useCallback(
        (name) => {
            const value = payload.find((x) => x.value === name)?.payload
                ?.percent
            if (value) {
                return formatNumber(value * 100)
            } else {
                return null
            }
        },
        [payload, legendPayload]
    )

    return (
        <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {legendPayload.map((item, index) => (
                <li
                    key={`pie-legend-${index}`}
                    className={`recharts-legend-item legend-item-${index}`}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                >
                    <svg
                        className="recharts-surface"
                        width="14"
                        height="14"
                        viewBox="0 0 32 32"
                        version="1.1"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginRight: '4px',
                        }}
                        onClick={() => toggleDisplayState(item, 'hidden')}
                    >
                        <path
                            stroke="none"
                            fill={item.color}
                            d="M0,4h32v24h-32z"
                            className="recharts-legend-icon"
                        ></path>
                    </svg>
                    <span
                        className="recharts-legend-item-text"
                        style={
                            activeLegendIndex === index
                                ? {
                                      fontWeight: 'bold',
                                  }
                                : null
                        }
                        onClick={() =>
                            toggleDisplayState(item, 'highlight', true)
                        }
                    >
                        {item.value}
                        {activeLegendIndex === index
                            ? `(${getLegendValue(item.value)}%)`
                            : null}
                    </span>
                </li>
            ))}
        </ul>
    )
}

const Chart = ({ data, showLegend }) => {
    const { colorMap, updateColorMap, getAssociatedColor } = useContext(ColorContext)
    // get the column name key for grouping, eg in { Value: <value>, District: <district>}
    // groupKey == 'District'
    const groupKey = useMemo(
        () => Object.keys(data[0]).find((x) => !x.includes('Value')),
        [data]
    )

    // get a list of all unique groups that are present in the data
    // and sort alphabetically
    const uniqueGroups = useMemo(
        () =>
            [...new Set(data.map((x) => x[groupKey]))]
                .sort()
                .map((x) => (x === null ? (x = '') : x)),
        [data, groupKey]
    )

    const [displayState, setDisplayState] = useState(
        createInitialState(uniqueGroups)
    )

    const toggleDisplayState = useCallback(
        (e, field, unique) => {
            // if only one can be selected at a time,
            // then we want to update state so that
            // all other values are false
            if (unique && !displayState[e.value][field]) {
                const newDisplayState = resetStateField(
                    displayState,
                    field,
                    false
                )
                setDisplayState({
                    ...newDisplayState,
                    [e.value]: {
                        ...newDisplayState[e.value],
                        [field]: true,
                    },
                })
            } else {
                // if we are hiding a pie slice,
                // and it is the currently highlighted slice,
                // we want to remove the highlight state on that slice
                if (field === 'hidden' && displayState[e.value].highlight) {
                    setDisplayState({
                        ...displayState,
                        [e.value]: {
                            highlight: false,
                            [field]: !displayState[e.value][field],
                        },
                    })
                } else {
                    // otherwise, we simply toggle the field
                    setDisplayState({
                        ...displayState,
                        [e.value]: {
                            ...displayState[e.value],
                            [field]: !displayState[e.value][field],
                        },
                    })
                }
            }
        },
        [displayState, setDisplayState]
    )

    const onShowLegendChange = useCallback(
        (showLegend) => {
            if (
                showLegend === 'all' &&
                Object.keys(displayState).some(
                    (x) => displayState[x].hidden === true
                )
            ) {
                setDisplayState(resetStateField(displayState, 'hidden', false))
            }
            if (
                showLegend === 'none' &&
                Object.keys(displayState).some(
                    (x) => displayState[x].hidden === false
                )
            ) {
                const resetHiddenField = resetStateField(
                    displayState,
                    'hidden',
                    true
                )
                const newDisplayState = resetStateField(
                    resetHiddenField,
                    'highlight',
                    false
                )
                setDisplayState(newDisplayState)
            }
        },
        [setDisplayState, displayState]
    )

    useEffect(() => {
        updateColorMap(groupKey, uniqueGroups)
    }, [uniqueGroups, groupKey])

    useEffect(() => {
        onShowLegendChange(showLegend)
    }, [showLegend])

    const colors = useMemo(
        () =>
            uniqueGroups.reduce((acc, curr, idx) => {
                return {
                    ...acc,
                    [curr]: getAssociatedColor(curr, groupKey),
                }
            }, {}),
        [uniqueGroups, groupKey, colorMap]
    )

    const displayGroups = useMemo(
        () =>
            uniqueGroups
                .filter((groupName) => !displayState[groupName].hidden)
                .sort(),
        [uniqueGroups, displayState]
    )

    const cells = useMemo(
        () =>
            displayGroups.map((groupName, idx) => {
                return (
                    <Cell
                        key={`cell-${groupName}-${idx}`}
                        dataKey={`${groupName}`}
                        stackId={'shared'}
                        fill={colors[groupName]}
                    />
                )
            }),
        [displayGroups, colors]
    )

    // legend includes all groups, so the active index for the
    // legend is the uniquegroups idx which is highlighted
    const activeLegendIndex = useMemo(
        () => uniqueGroups.findIndex((x) => displayState[x].highlight === true && !displayState[x].hidden === true),
        [displayState, uniqueGroups]
    )

    // pie chart does not include all groups, so the active index for the
    // legend is the displayGroups idx which is highlighted
    const activeIndex = useMemo(
        () =>
            displayGroups.findIndex((x) => displayState[x].highlight === true),
        [displayState, displayGroups]
    )

    const chartData = useMemo(
        () =>
            data
                .filter((d) => !displayState[d[groupKey]].hidden)
                .sort((a, b) =>
                    typeof a[groupKey] === 'string' &&
                    typeof b[groupKey] === 'string'
                        ? a[groupKey]
                              .toLowerCase()
                              .localeCompare(b[groupKey].toLowerCase())
                        : a[groupKey] - b[groupKey]
                ),
        [displayState, groupKey, data]
    )

    const legendPayload = useMemo(
        () =>
            uniqueGroups.map((groupName) => ({
                color: !displayState[groupName].hidden
                    ? colors[groupName]
                    : 'rgb(204, 204, 204)',
                type: 'rect',
                value: groupName,
                id: groupName,
            })),
        [displayState, colors, uniqueGroups]
    )

    return (
        <ResponsiveContainer height={'99%'} debounce={1}>
            <PieChart
                id="pieChart"
                margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <Pie
                    data={chartData}
                    dataKey={`Value`}
                    nameKey={groupKey}
                    valueKey={'Value'}
                    labelLine={false}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    displayState={displayState}
                >
                    {cells}
                </Pie>
                <Tooltip
                    content={
                        <CustomTooltip groupKey={groupKey} data={chartData} />
                    }
                />
                <Legend
                    content={
                        <CustomLegend
                            legendPayload={legendPayload}
                            activeLegendIndex={activeLegendIndex}
                            displayState={displayState}
                            toggleDisplayState={toggleDisplayState}
                        />
                    }
                    verticalAlign="top"
                    formatter={(a, b) => {
                        return b.value ? b.value : 'Null'
                    }}
                    data={data}
                    wrapperStyle={{
                        display: showLegend ? '' : 'none',
                        top: 1,
                        left: 0,
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                        alignItems: 'center',
                        width: '100%',
                    }}
                />
            </PieChart>
        </ResponsiveContainer>
    )
}

export default () => {
    const { widgetData: widget, showLegend } = useContext(WidgetDataContext)
    const data = widget ? widget.Data : null
    //added to prevent errors from groupKeys with null values

    const noNullData = data.map((x) => {
        Object.keys(x).map((y) => (x[y] === null ? (x[y] = 'Null') : x[y]))
        return x
    })
    return <Chart data={noNullData} showLegend={showLegend} />
}
