import React from 'react'
import AdminTable from './AdminTable'
import { Link } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import AdminLayout from '../../elem/admin/AdminLayout'
const AdminDashboard = () => {
    return (
        <>
            <AdminLayout title={'Dashboard Admin'}>
                <AdminTable />
                <div className="has-text-centered">
                    <button className="button is-info">
                        <Link
                            className="has-text-white"
                            to={`/admin/dashboard/create`}
                        >
                            <div className="newDashboard">
                                New Dashboard <FaPlus />
                            </div>
                        </Link>
                    </button>
                </div>
            </AdminLayout>
        </>
    )
}

export default AdminDashboard
