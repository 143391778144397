import React, { useContext } from 'react'
import { DashboardContext } from '../../wrappers/DashboardContext'
import LoadingSpinner from '../../elem/LoadingSpinner'
import DashboardLink from '../../elem/landing-page/DashboardLink'
import LandingPageWrapper from '../../elem/landing-page/LandingPageWrapper'

const LandingPage = () => {
    const { loading, dashboards } = useContext(DashboardContext)

    if (loading || !dashboards) {
        return <LoadingSpinner />
    }

    return (
        <LandingPageWrapper>
            <div style={{zIndex: 3}} className="myDashboardsText is-size-4">
                My Dashboards:
            </div>
            <div className="landingPageLinks">
                {dashboards.map((dashboard, idx) => {
                    return <DashboardLink key={`dashboard-link-${idx}`}dashboard={dashboard} />
                })}
            </div>
        </LandingPageWrapper>
    )
}

export default LandingPage
