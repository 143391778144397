import React, { useEffect, useRef, useContext, useState } from 'react'
import Map from 'ol/Map'
import { defaults as defaultInteractions } from 'ol/interaction'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { unByKey } from 'ol/Observable'

import withConfig from '../../../../wrappers/withConfig'
import { WidgetDataContext } from '../../../../wrappers/WidgetDataContext'

import {
    displayPopup, zoomControls,
} from '../mapConfig'
import {
    themeMapStyleFunction,
    getHeatMapColorKeyFromRange,
} from './styleFunctions'
import MapTooltip from '../Tooltip'
import MapLegend from './MapLegend'
import { FeatureContext } from './FeatureContext'
import { CurrentDashboardContext } from '../../../../wrappers/CurrentDashboardContext'
import { formatNumber } from '../../../../../utils/numberFormatting'
import { ZoomToExtent } from 'ol/control'

const getTooltipDataFromFeature = (tooltipState, widget, activeGroupBy) => {
    if (tooltipState) {
        const { feature } = tooltipState
        return [{
            field: activeGroupBy,
            value: feature && feature.get('FeatureName')
        }, {
            field: widget ? widget.ValueLabel : null,
            value: feature && formatNumber(feature.get('Value'))
        }]
    }
    return []
}

const ThemeMap = ({ config }) => {
    // load context
    const {
        loading,
        widgetData: widget,
        activeGroupBy,
    } = useContext(WidgetDataContext)
    const { dashboardName, expandedWidgetId } = useContext(CurrentDashboardContext)
    const { features } = useContext(FeatureContext)

    // get map config associated w/ the dashboard name
    const { MAP: mapConfig } = config
    const { INITIAL_ZOOM_LEVEL, CENTER_LAT_LNG } = mapConfig[dashboardName]


    // create internal state + refs
    const mapRef = useRef(null)
    const [map, setMap] = useState(null)
    const [popupKey, setPopupKey] = useState(null)
    const [tooltipState, setTooltipState] = useState(null)
    const [dataRange, setDataRange] = useState([])

    // on load, set up the map
    useEffect(() => {
        const map = new Map({
            target: mapRef.current,
            // controls: zoomControls(ZOOM_TO_EXTENT),
            controls: zoomControls(null),
            interactions: defaultInteractions({ mouseWheelZoom: false }),
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                projection: 'EPSG:4326',
                center: CENTER_LAT_LNG,
                zoom: INITIAL_ZOOM_LEVEL,
            }),
        })

        // set initial extent to that of the view
        const initialExtent = map.getView().calculateExtent()
        map.getControls().getArray().find(x => x instanceof ZoomToExtent).extent = initialExtent

        setMap(map)
    }, [ CENTER_LAT_LNG, INITIAL_ZOOM_LEVEL, /*ZOOM_TO_EXTENT*/])

    // update size on load finish + expanded change
    useEffect(() => {
        if (map && mapRef) {
            map.setSize(mapRef.current.clientWidth, mapRef.current.clientHeight)
            map.updateSize()
        }
    }, [loading, map, mapRef, expandedWidgetId])

    // draw the counties vector layer,
    // and style according to the data
    // for each county
    useEffect(() => {
        if (widget && widget.Data && map && activeGroupBy && features) {
            const data = widget.Data.filter((x) => !!x[activeGroupBy]) // get data + filter out null counties
            const values = data.map((x) => x.Value)
            const range = [Math.min(...values), Math.max(...values)]
            setDataRange(range) // set data range for use by the map legend
            features.forEach((feature) => {
                const featureName = feature.get('FeatureName')
                const featureData = data.find(
                    (d) =>
                        `${d[activeGroupBy]}`.toLowerCase() === `${featureName}`.toLowerCase()
                        || Number(d[activeGroupBy]) === Number(featureName)
                )
                if (featureData) {
                    feature.set('Value', featureData.Value)
                } else {
                    feature.set('Value', 0)
                }
                const colorKey = getHeatMapColorKeyFromRange(
                    feature.get('Value'),
                    range
                )
                feature.set('ColorKey', colorKey)
            })
            const vectorSource = new VectorSource({
                features: features,
            })
            const vectorLayer = new VectorLayer({
                source: vectorSource,
                style: themeMapStyleFunction,
            })
            map.addLayer(vectorLayer)
        }
    }, [widget, map, activeGroupBy, features])

    // add tooltip event listeners when map loads
    useEffect(() => {
        if (map) {
            if (!popupKey) {
                const key = map.on('pointermove', (e) =>
                    displayPopup(e, map, setTooltipState)
                )
                setPopupKey(key)
            }
        } else {
            if (popupKey) {
                unByKey(popupKey)
                setPopupKey(null)
            }
        }
    }, [map, popupKey])

    if (loading) {
        return null
    }

    return (
        <div className="mapFlexWrapper">
            <MapLegend dataRange={dataRange} />
            <div className="mapWrapper">
                <div
                    id="map"
                    ref={mapRef}
                    className={`mapContainer ${loading ? 'is-hidden' : ''}`}
                ></div>
                <MapTooltip
                    {...tooltipState}
                    tooltipData={getTooltipDataFromFeature(tooltipState, widget, activeGroupBy)}
                />
            </div>
        </div>
    )
}

export default withConfig(ThemeMap)