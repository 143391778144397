import React from 'react'
import ReactDOM from 'react-dom'
import VectorLayer from 'ol/layer/Vector'
import Vector from 'ol/source/Vector'
import { Select } from 'ol/interaction'
import { ZoomToExtent } from 'ol/control'
import Polygon from 'ol/geom/Polygon'

import { FaGlobe } from 'react-icons/fa'
import { IconTooltip } from '../../Tooltip'
import { getTooltipPositionProps } from './Tooltip'

const createLabel = (element, name, tooltip) => {
    const div = document.createElement('div')
    div.setAttribute('class', 'zoomToExtentDiv')
    if (name && tooltip) {
        div.setAttribute('data-for', name)
        div.setAttribute('data-tip', tooltip)
    }
    ReactDOM.render(element, div)
    return div
}

const getLayers = () => {
    return {
        polygon: new VectorLayer({
            source: new Vector(),
            title: 'Polygon',
            visible: false,
        }),
    }
}

const zoomControls = (extent) => {
    return [
        new ZoomToExtent({
            extent,
            label: createLabel(
                <>
                    <FaGlobe />
                    <IconTooltip id={'zoom-to-extent'} />
                </>,
                'zoom-to-extent',
                'Zoom to Full Extent'
            ),
            tipLabel: '',
            className: 'ol-zoom-extent zoomToExtentWrapper',
        })
    ]
}

const getSelectControl = () => {
    const control = new Select()

    const on = (source, sharedPageKey, setSharedPageId, layer) => {
        const mouseDownKey = control.on('select', function(e) {
            var tolerance = 10
            // if the layer is not visible, pass this selection over
            if (!layer.getVisible()) {
                return
            }
            
            if (e.selected.length) {
                const map = e.mapBrowserEvent.map
                const coordinates = e.selected[0].getGeometry().flatCoordinates
                const targetPixel = map.getPixelFromCoordinate(coordinates)

                const pixel1 = [
                    targetPixel[0] - tolerance / 2,
                    targetPixel[1] - tolerance / 2,
                ]
                const pixel2 = [
                    targetPixel[0] - tolerance / 2,
                    targetPixel[1] + tolerance / 2,
                ]
                const pixel3 = [
                    targetPixel[0] + tolerance / 2,
                    targetPixel[1] + tolerance / 2,
                ]
                const pixel4 = [
                    targetPixel[0] + tolerance / 2,
                    targetPixel[1] - tolerance / 2,
                ]

                const point1 = map.getCoordinateFromPixel(pixel1)
                const point2 = map.getCoordinateFromPixel(pixel2)
                const point3 = map.getCoordinateFromPixel(pixel3)
                const point4 = map.getCoordinateFromPixel(pixel4)

                const polygon = new Polygon([[point1, point2, point3, point4]])
                const newFilterData = source
                    .getFeatures()
                    .filter(feature => 
                        polygon.intersectsExtent(
                            feature.getGeometry().getExtent()
                        )
                    )

                if (newFilterData && newFilterData.length && sharedPageKey) {
                    const sharedPageId = newFilterData[0].get(sharedPageKey)
                    if (sharedPageId) {
                        setSharedPageId(sharedPageId)
                    }
                    e.target.getFeatures().clear()
                }
            }
        })
        const keys = [mouseDownKey]
        return keys
    }

    return {
        on,
        control
    }
}


const displayPopup = async (e, map, setTooltipState) => {
    const pixel = map.getEventPixel(e.originalEvent)
    const mapWidth = e.originalEvent.currentTarget.scrollWidth
    const bottomPixel = e.originalEvent.currentTarget.clientHeight 
    const styleProps = getTooltipPositionProps(pixel, mapWidth, bottomPixel)

    // unhighlight the previous feature
    const previousFeature = map.get('currentFeature')
    if (previousFeature) {
        previousFeature.set('highlighted', 0)
    }

    const feature = map.forEachFeatureAtPixel(pixel, function(feature, layer) {
        return feature
    })

    if (feature) {
        // highlight the new feature
        feature.set('highlighted', 1)
        map.set('currentFeature', feature)
        setTooltipState({
            ...styleProps,
            feature
        })
    } else {
        setTooltipState(prevState => ({
            ...prevState,
            feature: null,
        }))
    }
}
export { getLayers, zoomControls, displayPopup, getSelectControl }
