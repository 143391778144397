import React, { createContext, useState, useEffect, useContext } from 'react'
import GeoJSON from 'ol/format/GeoJSON'

import toast from '../../../Toast'
import { WidgetDataContext } from '../../../../wrappers/WidgetDataContext'
import { CurrentDashboardContext } from '../../../../wrappers/CurrentDashboardContext'
import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import withConfig from '../../../../wrappers/withConfig'

const FeatureContext = createContext(null)

const getFeatureFilepathAssociatedWithGroupBy = (widget, activeGroupBy) => {
    const groupByOptions = widget.GroupBy.split('|')
    const filenameOptions = widget.FeatureFiles.split('|')
    const activeIndex = groupByOptions.findIndex(
        (groupBy) => groupBy === activeGroupBy
    )
    const activeFilename = filenameOptions[activeIndex]
    return activeFilename
}

const FeatureContextProvider = ({ config, children }) => {
    // get the map widget data
    const { widgetData: widget, activeGroupBy } = useContext(WidgetDataContext)
    const { dashboardName } = useContext(CurrentDashboardContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { MAP: mapConfig } = config
    const { SOURCE_PROJECTION } = mapConfig[dashboardName]
    const targetProjection = 'EPSG:4326'
    const sourceProjection = SOURCE_PROJECTION ? SOURCE_PROJECTION : targetProjection
    const [features, setFeatures] = useState([])

    useEffect(() => {
        // get the file name we are going to fetch from
        // the /public directory
        const featureFilename = getFeatureFilepathAssociatedWithGroupBy(
            widget,
            activeGroupBy
        )

        // fetch the file contents
        authenticatedFetch(`/${featureFilename}`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                const f = new GeoJSON({
                    dataProjection: sourceProjection,
                    featureProjection: targetProjection
                }).readFeatures(response)
                setFeatures(f)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message: `Map Feature File: No file found at ${featureFilename}`,
                })
            })
    }, [activeGroupBy, widget])

    return (
        <FeatureContext.Provider value={{ features }}>
            {children}
        </FeatureContext.Provider>
    )
}

export { FeatureContext }
export default withConfig(FeatureContextProvider)
