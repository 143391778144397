import React, { useContext, useState, useRef, useEffect } from 'react'

import { WidgetDataContext } from '../../../../wrappers/WidgetDataContext'
import Scene from './Scene'
import { prepareData } from './utils'
import MapLegend from './MapLegend'
import MapTooltip from '../Tooltip'
import TooltipContextProvider, { TooltipContext } from './TooltipContext'
import { CurrentDashboardContext } from '../../../../wrappers/CurrentDashboardContext'
import MapContextProvider from '../MapContext'
import { getLegendDataFromLayers } from './utils'

const getTooltipDataFromFeature = (tooltipState) => {
    if (tooltipState) {
        const { data } = tooltipState
        return Object.keys(data).map(fieldName => (
            {
                field: fieldName,
                value: data[fieldName]
            }
        ))
    }
    return []
}

export default () => {
    const { sharedPageKey } = useContext(CurrentDashboardContext)
    const { loading, widgetData: widget } = useContext(WidgetDataContext)
    const mapRef = useRef(null)
    const compassRef = useRef(null)
    const [layerData, setLayerData] = useState([])

    useEffect(() => {
        if (widget && widget.Data) {
            // obtain layer data + stringified options object from
            // the widget
            const { Data: data, WidgetOptions: stringifiedOptions } = widget

            // parse the options object and get the "layers" field
            let parsedOptions = stringifiedOptions
                ? JSON.parse(stringifiedOptions)
                : { layers: [] }
            const { layers: options } = parsedOptions

            // prepare data - turns into shape
            // [{layerName: <layerName_1>, layerType: <layerType_1>, data: <data_1>, style: <style_1>}, ...]
            const layerData = prepareData(data, options, sharedPageKey)
            setLayerData(layerData)
        }
    }, [widget, sharedPageKey])

    if (loading) {
        return null
    }

    return (
        <div className="mapFlexWrapper">
            <MapContextProvider data={layerData} legendFunction={getLegendDataFromLayers}>
                <MapLegend />
                <div className="mapWrapper">
                    <img ref={compassRef} src={'/compass.svg'} className='compass' alt={'3d map compass'}/>
                    <TooltipContextProvider>
                        <div
                            ref={mapRef}
                            className={`mapContainer ${loading ? 'is-hidden' : ''}`}
                        >
                            <Scene data={layerData} compassRef={compassRef} />
                        </div>
                        <TooltipContext.Consumer>
                            {({ tooltipState }) => (
                                <MapTooltip
                                    {...tooltipState}
                                    tooltipData={getTooltipDataFromFeature(
                                        tooltipState
                                    )}
                                />
                            )}
                        </TooltipContext.Consumer>
                    </TooltipContextProvider>
                </div>
            </MapContextProvider>
        </div>
    )
}
