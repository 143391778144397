import React from 'react'
import Breadcrumb from '../../features/admin/Breadcrumb'

const AdminLayout = ({ title, children }) => (
    <div className={'section admin'}>
        <Breadcrumb />
        <div className={'container'}>
            <h3 className={'title is-3'}>{title}</h3>
        </div>
        <div className={'container'}>{children}</div>
    </div>
)
export default AdminLayout