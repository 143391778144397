import React, { useContext } from 'react'

import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'
import Select from '../../select/Select'

export default () => {
    const { widgetData, activeGroupBy, setGroupBy } = useContext(
        WidgetDataContext
    )
    const groupBy = widgetData ? widgetData.GroupBy : null
    if (!groupBy || !groupBy.length > 1) {
        return null
    }
    // split the groupBy field (eg Form Type|Form Status) into separate values
    // (eg ['Form Type', 'Form Status'])
    const options = groupBy.split('|').map((x) => ({ label: x, value: x }))
    
    // if there is only one option, do not show the dropdown
    if (options.length === 1) {
        return null
    }
    
    const currentValue = !!activeGroupBy
        ? options.find((option) => option.value === activeGroupBy)
        : options[0]
    return (
        <div className="groupByWrapper">
            {/* <div className="groupByText">By</div> */}
            <div className="groupBySelectWrapper">
                <Select
                    options={options}
                    value={currentValue}
                    onChange={({ value }) => setGroupBy(value)}
                />
            </div>
        </div>
    )
}
