import React, { useMemo, useEffect, useState } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import { FaEdit, FaTrashAlt, FaAngleUp, FaAngleDown } from 'react-icons/fa'
import { Link } from 'react-router-dom'


const ManageCell = ({ value, dashboardName, name }) => {
    return (
        <>
            <Link
                to={{
                    pathname: `/admin/dashboard/${dashboardName}/page/${value}`,
                    dashboardProps: {
                        id: value,
                        name: dashboardName,
                    },
                }}
            >
                <button className="button is-small is-info">
                    <FaEdit />
                </button>
            </Link>
            <Link
                to={{
                    pathname: `/delete`,
                    deleteProps: {
                        id: value,
                        name: name,
                        dashboardName: dashboardName,
                        itemType: 'page',
                    },
                }}
            >
                <button className="button is-small is-info">
                    <FaTrashAlt />
                </button>
            </Link>
        </>
    )
}

const createColumns = (name) => [
    { Header: 'Page Id', accessor: 'PageId' },
    { Header: 'Page Name', accessor: 'PageName' },
    { Header: 'URL', accessor: 'Location' },
    {
        Header: 'Number of Widgets',
        accessor: 'Widgets'
    },
    {
        Header: 'Manage',
        accessor: 'PageId',
        id: '5',
        Cell: ({ cell, row }) => (
            <ManageCell
                dashboardName={name ? name : row.original.DashboardName}
                value={cell.value}
                name={row.original.PageName}
            />
        ),
    },
]

const EditTable = ({ pages, name }) => {

    const [data, setData] = useState([])

    useEffect(() => {  
        name ? setData(pages.map((page) => ({...page, DashboardName: name}))) : setData(pages)
    }, [name, pages])  

    const columns = useMemo(() => createColumns(name), [name])
    const tableData = useMemo(() => data, [data])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: tableData,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useSortBy,
        usePagination
    )

    return (
        <div className="dashboardWrapper">
            <h4 className="title is-4">Dashboard Pages</h4>
            <table
                {...getTableProps()}
                className="table is-fullwidth is-striped"
                style={{
                    height: '200px',
                }}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? <FaAngleUp />
                                      : <FaAngleDown />
                                    : ''}
                                </span>
                              </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    {'<'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
            </div>
        </div>
    )
}

export default EditTable
