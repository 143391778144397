import React, { useContext } from 'react'
import { CurrentDashboardContext } from '../wrappers/CurrentDashboardContext'
import DateBarComponent from './date/BarComponent'
import LayoutEditorToggle from './LayoutEditorToggle'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import TopBarDropdown from './TopBarDropdown'
import withConfig from '../wrappers/withConfig'
import { UserContext } from '../wrappers/UserContext'
import { hasAccessToAdmin } from '../../utils/user/permissions'
import AdminLink from './landing-page/AdminLink'
import LogoutButton from './user/LogoutButton'
import ManageUsersButton from './user/ManageUsersButton'

export default withConfig(({ config }) => {
    const {
        currentWidgetGroup: { PageName },
        dashboardName,
        pageLoading,
    } = useContext(CurrentDashboardContext)
    const { user, roles } = useContext(UserContext)
    const { IS_PUBLIC } = config

    return (
        <div className="topBarWrapper">
            <HelmetProvider>
                <Helmet>
                    <title>
                        {dashboardName} {!IS_PUBLIC ? `Director ` : ''}Dashboard
                    </title>
                </Helmet>
            </HelmetProvider>
            <div className="widgetGroupNameWrapper">
                <div className="widgetGroupName">
                    <p className="dashboardName">{dashboardName} </p>
                    {!pageLoading && <p className="pageName">- {PageName}</p>}
                    <div className="mobileName">
                        <TopBarDropdown name={PageName} />
                    </div>
                </div>
                <div className="topBarControls">
                    <LayoutEditorToggle />
                    <DateBarComponent />
                    {user && (
                        <div className="userControls">
                            {hasAccessToAdmin(roles) ? <AdminLink /> : null}
                            {hasAccessToAdmin(roles) ? <ManageUsersButton /> : null}
                            <LogoutButton />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
})
