import React, { useContext } from 'react'
import { FaGripHorizontal, FaSave, FaSyncAlt, FaTimes } from 'react-icons/fa'
import { CurrentDashboardContext } from '../wrappers/CurrentDashboardContext'

export default () => {
    const {
        editingLayout,
        saveLayoutState,
        startLayoutEdit,
        cancelLayoutEdit,
        resetLayoutState
    } = useContext(CurrentDashboardContext)

    return (
        <div className="topBarEditComponentWrapper ">
            <div
                className="topBarEditToggle toggle"
                // data-tip={`${editingLayout ? 'Save Layout' : 'Edit Layout'}`}
                // data-for={'editLayout'}
                onClick={() => {
                    editingLayout ? saveLayoutState() : startLayoutEdit()
                }}
            >
                {editingLayout ? <FaSave /> : <FaGripHorizontal />}
                <div className="toggle">
                    {`${editingLayout ? 'Save' : 'Edit'} Layout`}
                </div>
                {/* <Tooltip id={'editLayout'} /> */}
            </div>
            {editingLayout && (
                <>
                    <div
                        className="topBarEditToggle toggle resetToggle"
                        // data-tip={`Reset Layout State`}
                        // data-for={'resetLayout'}
                        onClick={() => {
                            resetLayoutState()
                        }}
                    >
                        <FaSyncAlt />
                        <div className="toggle">
                            {`Reset Layout`}
                        </div>
                        {/* <Tooltip id={'resetLayout'} /> */}
                    </div>
                    <div
                        className="topBarEditToggle toggle"
                        // data-tip={`Cancel`}
                        // data-for={'cancelEdit'}
                        onClick={() => {
                            cancelLayoutEdit()
                        }}
                    >
                        <FaTimes />
                        <div className="toggle">
                            {`Cancel`}
                        </div>
                        {/* <Tooltip id={'cancelEdit'} /> */}
                    </div>
                </>
            )}
        </div>
    )
}
