import React, { useContext } from 'react'
import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'
import NoDataComponent from '../NoDataComponent'
import { formatNumber } from '../../../../utils/numberFormatting'

const getValue = data => {
    if (data && data && data.length && data[0].Value) {
        return formatNumber(data[0].Value)
    } else {
        return <NoDataComponent />
    }
}

export default () => {
    const { widgetData: widget } = useContext(WidgetDataContext)
    const data = widget ? widget.Data : null
    const value = getValue(data)
    return (
        <div className="widget">
            <div className={"numberWidgetWrapper"}>
                <div className={"numberWidgetText"}>
                    {value}
                </div>    
            </div>
        </div>
    )
}
