import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

export default () => {
    const location = useLocation()
    const dashRegex = location.pathname.match(/dashboard\/([\w\s-]*)\/?/)
    const pageRegex = location.pathname.match(/page\/([\w\s-]*)\/?/)
    const widgRegex = location.pathname.match(/widget\/([\w\s-]*)\/?/)

    const dashboardName = dashRegex && dashRegex[1] ? dashRegex[1] : null
    const pageId = pageRegex && pageRegex[1] ? pageRegex[1] : null
    const widgetId = widgRegex && widgRegex[1] ? widgRegex[1] : null

    return (
        <nav className="breadcrumb is-centered" aria-label="breadcrumbs">
            <ul>
                <li>
                    <NavLink
                        exact
                        activeClassName="is-active"
                        className="navbar__link"
                        to="/"
                    >
                        Director Dashboard
                    </NavLink>
                </li>
                <li>
                    {' '}
                    <NavLink
                        exact
                        activeClassName="is-active"
                        className="navbar__link"
                        to="/admin/dashboard"
                    >
                        Admin Dashboard
                    </NavLink>
                </li>
                <li className={!dashboardName ? 'hidden' : null}>
                    {' '}
                    <NavLink
                        exact
                        activeClassName="is-active"
                        style={{ textTransform: 'capitalize' }}
                        to={`/admin/dashboard/${dashboardName}`}
                    >
                        {dashboardName !== 'create'
                            ? `${dashboardName}`
                            : `${dashboardName} Dashboard`}
                    </NavLink>
                </li>
                <li className={!pageId || !dashboardName ? 'hidden' : null}>
                    {' '}
                    <NavLink
                        exact
                        activeClassName="is-active"
                        style={{ textTransform: 'capitalize' }}
                        to={`/admin/dashboard/${dashboardName}/page/${pageId}`}
                    >
                        {pageId !== 'create'
                            ? `Page ${pageId}`
                            : `${pageId} Page`}
                    </NavLink>
                </li>
                <li className={!widgetId || !dashboardName ? 'hidden' : null}>
                    {' '}
                    <NavLink
                        exact
                        activeClassName="is-active"
                        style={{ textTransform: 'capitalize' }}
                        to={`/admin/dashboard/${dashboardName}/page/${pageId}/widget/${widgetId}`}
                    >
                        {widgetId !== 'create'
                            ? `Widget ${widgetId}`
                            : `${widgetId} Widget`}
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}
