import React, { useContext, useState } from 'react'
import EditPageForm from './EditPageForm'
import EditPageTable from './EditPageTable'
import { Link } from 'react-router-dom'
import { CurrentDashboardContext } from '../../../wrappers/CurrentDashboardContext'
import AdminLayout from '../../../elem/admin/AdminLayout'
import { FaPlus } from 'react-icons/fa'

const EditPage = () => {
    const {
        widgetGroups,
        currentWidgetGroup,
        currentDashboard,
        pageId,
        setNewObject,
    } = useContext(CurrentDashboardContext)
    const id = Number(pageId)
    const [edited, setEdited] = useState(false)

    if (!currentDashboard || !pageId || !Object.keys(currentWidgetGroup).length || Number(pageId) !== currentWidgetGroup.PageId) {
        return null
    }

    const page = currentWidgetGroup
    const pages = widgetGroups
        .filter(
            (widget) =>
                widgetGroups.indexOf(widget.PageId) ===
                widgetGroups.lastIndexOf(widget.PageId)
        )
        .map((widget) => ({ name: widget.PageName, id: widget.PageId }))
        .filter((widget) => widget.id !== id)

    const closeForm = (edit) => {
        setEdited(edit)
    }

    if (edited) {
        setNewObject(edited)
    }

    return (
        <div className="editDashboard">
            <AdminLayout title={'Edit Page'}>
                <EditPageForm
                    pages={pages}
                    closeForm={closeForm}
                    page={page ? page : null}
                />
                <hr />
                <EditPageTable
                    widgets={page && page.Widgets ? page.Widgets : []}
                />
                <div className="has-text-centered">
                    <button className="button is-info">
                        <Link
                            className="has-text-white"
                            to={{
                                pathname: `/admin/dashboard/${currentDashboard.DashboardName}/page/${id}/widget/create`,
                                widgetProps: {
                                    page: id,
                                    dashboard: currentDashboard.DashboardName,
                                },
                            }}
                        >
                            <div className="newDashboard">
                                New Widget <FaPlus />
                            </div>
                        </Link>
                    </button>
                    <br/>
                    <button className="button is-info">
                        <Link
                            className="has-text-white"
                            to={{
                                pathname: `/admin/dashboard/${currentDashboard.DashboardName}/page/${id}/layout`,
                            }}
                        >
                            <div className="newDashboard">
                                Page Layout Editor
                            </div>
                        </Link>
                    </button>
                </div>
            </AdminLayout>
        </div>
    )
}

export default EditPage
