import React from 'react'
import ReactSelect from 'react-select'
const selectStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: '1em',
        maxHeight: '2em'
    }),
    container: (provided) => ({
        ...provided,
        minHeight: '1em',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '2em',
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '1em',
        maxHeight: '2em',
        padding: '0px 0px 0px 1px',
        width: '2em'
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 3,
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '2px',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '1px',
        width: '1.5em'
    }),
}

export default ({ options, value, defaultValue, onChange, disabled }) => (
    <ReactSelect
        className="select is-small is-multiple is-fullwidth reactSelect"
        classNamePrefix="reactSelect"
        inputProps={{autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off' }}
        options={options}
        styles={selectStyles}
        menuPlacement="auto"
        value={value}
        defaultValue={defaultValue}
        placeholder={defaultValue}
        isDisabled={disabled}
        onChange={onChange ? onChange : null}
    />
)
