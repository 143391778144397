const compactFormat = new Intl.NumberFormat('en-US', { notation: "compact", compactDisplay: "short" })
const longFormat = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2})

const formatNumber = (value, type) => {
    if (type === 'compact') {
        return compactFormat.format(value)
    } else {
        return longFormat.format(value)
    }
}

export { formatNumber }