import React, { useContext } from 'react'
import { MapContext } from '../MapContext'
import { WidgetDataContext } from '../../../../wrappers/WidgetDataContext'

export default () => {
    const { showLegend } = useContext(WidgetDataContext)
    const { legendState: legendData, toggleLayerVisibility } = useContext(
        MapContext
    )
    return (
        <div className="mapLegendWrapper" style={{display: showLegend ? '' : 'none'}}>
            {legendData.map((legendItem, idx) => {
                const visible = legendItem.visible
                const backgroundColor = visible ? legendItem.fillColor : 'white'
                return (
                    <div
                        key={`legend-item-${idx}`}
                        className="mapLegendItemWrapper"
                        onClick={() =>
                            toggleLayerVisibility(legendItem.layerName)
                        }
                    >
                        <div className="mapLegendItemColor">
                            <span
                                className="mapLegendItemSwatch"
                                style={{
                                    backgroundColor,
                                    border: legendItem.strokeColor
                                        ? `solid 1px ${legendItem.strokeColor}`
                                        : null,
                                    borderRadius:
                                        legendItem.geometryType === 'Point'
                                            ? '10px'
                                            : null,
                                }}
                            />
                        </div>
                        <div className="mapLegendItemText">
                            {legendItem.layerName}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
