import React from 'react'

import Map from './Map'
import FeatureContextProvider from './FeatureContext'

export default () => {
    return (
        <>
            <FeatureContextProvider>
                <Map />
            </FeatureContextProvider>
        </>
    )
}