import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { Controller } from 'react-hook-form'
import { FaInfoCircle } from 'react-icons/fa'
import { DefaultTooltip as Tooltip } from '../Tooltip'

export default ({
    options,
    control,
    initialValue,
    name,
    disabled,
    label,
    helper,
    rules,
    example,
    onChange,
    className,
    errors,
    value,
}) => {
    return (
        <div className={`${className}`}>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label is-horizontal reactSelect">
                        {label}
                    </label>
                </div>
                <div className="field-body is-full-width">
                    <div className="field has-addons">
                    {helper && (
                            <div className="control">
                                <button type="button" className="button is-meduim is-static no-margin">
                                    <span
                                        data-tip={`${helper}<br>${
                                            example ? `<br/> Example: ${example}` : ''
                                        }`}
                                        data-for={name}
                                        className="icon is-size-7 is-left formHelper"
                                    >
                                        <FaInfoCircle />
                                        <Tooltip id={name} place={'right'} />
                                    </span>
                                </button>
                            </div>
                        )}
                        <div className="control is-expanded">
                            <Controller
                                as={
                                    <CreatableSelect
                                        isMulti
                                        components={{
                                            DropdownIndicator: null,
                                        }}
                                        className="select is-small is-multiple is-fullwidth reactSelect is-full-height"
                                        classNamePrefix="reactSelect"
                                        name={name}
                                        options={options}
                                        menuPlacement="auto"
                                        isDisabled={disabled}
                                        placeholder={
                                            'Type something and press enter...'
                                        }
                                        helper="Each Group By Option should be the name of a column that you would like to be able to use in the ‘Group By’ clause of the Widget Query."
                                        example="For example, if you wish to see a Grid that has counts of Permits which can be grouped by District, County, or Township, District + County + Township should each be entered as individual options in this field."
                                    />
                                }
                                name={name}
                                control={control}
                                defaultValue={
                                    initialValue ? initialValue : null
                                }
                                key={`my_unique_select_key__${JSON.stringify(
                                    value
                                )}`}
                                value={value}
                                rules={rules}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <p className="help is-danger">
                {errors && errors[name] ? errors[name].message : null}
            </p>
        </div>
    )
}
