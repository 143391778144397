import React, { useContext } from 'react'
import { UserContext } from '../../wrappers/UserContext'

const LogoutButton = () => {
    const { client } = useContext(UserContext)
    return (
        <div className="button is-light is-small" onClick={function() {client.signoutRedirect()}}>
            Logout
        </div>
    )
}

export default LogoutButton