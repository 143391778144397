import React, { useEffect, createContext, useState, useCallback } from 'react'
import { AuthProvider, useAuth, UserManager } from 'oidc-react'

import { getRoles } from '../../utils/user/permissions'
import withConfig from '../wrappers/withConfig'
import dayjs from 'dayjs'
// import settings from '../../utils/constants/userSettings'

const UserContext = createContext(null)

const AppUserContextProvider = withConfig(({ loadingUser, children }) => {
    // const { userManager: client, userData: user } = useAuth()
    const { userManager: client } = useAuth()
    const [roles, setRoles] = useState("")
    const [ user, setUser ] = useState(null)
    
    const [idleTime, setIdleTime] = useState(dayjs().add(60, 'minute'))
    
    const resetIdleTime = () => {
        setIdleTime(dayjs().add(60, 'minute'))
    }

    // on app load, set the idle time onClick updater on the document
    useEffect(() => {
        document.addEventListener('click', resetIdleTime)
        return () => {
            document.removeEventListener('click', resetIdleTime)
        }
    }, [])

    const checkIdleState = useCallback(async () => {
        const now = dayjs()
        const idleTimeRemaining = idleTime.diff(now, 'minute')
        if (idleTimeRemaining < 1) {
            await client.signoutRedirect()
        }
    }, [user, client, idleTime])

    // look at the idle state and logout when idle time is less than 1 minute
    useEffect(() => {
        // check every 3 seconds
        const interval = setInterval(() => {
            checkIdleState()
        }, 3000)
        // clear the interval when the user changes or the page is refreshed
        return () => {
            clearInterval(interval)
        }
    }, [checkIdleState])

    // when the user changes, update roles list
    useEffect(() => {
        setRoles(getRoles(user))
    }, [user])

    useEffect(() => {
        const getUser = async () => {
            try {
                const u = await client.getUser()
                setUser(u)
            } catch (e) {
                console.log('UserContext: Error getting user -- ', e)
            }
        }
        if (client) {
            getUser()
        }
    }, [client])

    return (
        <UserContext.Provider
            value={{
                user,
                roles,
                client,
                loadingUser
            }}
        >
            {children}
        </UserContext.Provider>
    )
})

const UserContextProvider = withConfig(({ config, children }) => {
    
    const settings = {
        authority: config.REACT_APP_OIDC_AUTHORITY,
        client_id: config.REACT_APP_OIDC_CLIENT_ID,
        client_secret: config.REACT_APP_OIDC_CLIENT_SECRET,
        redirect_uri: config.REACT_APP_OIDC_CLIENT_REDIRECT_URI,
        silent_redirect_uri: config.REACT_APP_OIDC_CLIENT_SILENT_REDIRECT_URI,
        response_type: config.REACT_APP_OIDC_RESPONSE_TYPE,
        scope: config.REACT_APP_OIDC_SCOPE,
        post_logout_redirect_uri: config.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
        automaticSilentRenew: true
        // loadUserInfo: true
    }
    
    const userManager = new UserManager(settings)
    return (
        <AuthProvider
            userManager={userManager}
            // authority={settings.authority}
            // clientId={settings.client_id}
            // clientSecret={settings.client_secret}
            // redirectUri={settings.redirect_uri}
            // responseType={settings.response_type}
            // scope={settings.scope}
            // automaticSilentRenew={true}
            autoSignIn={false}
        >
            <AppUserContextProvider>{children}</AppUserContextProvider>
        </AuthProvider>
    )
})

export { UserContext }
export default UserContextProvider
