import React, { useContext, useMemo } from 'react'
import { useTable } from 'react-table'
import LoadingSpinner from '../../elem/LoadingSpinner'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { DashboardContext } from '../../wrappers/DashboardContext'

const Cell = ({ value, name, description }) => {
    return (
        <>
            <Link
                to={{
                    pathname: `/admin/dashboard/${name}`,
                    dashboardProps: {
                        id: value,
                        DashboardName: name,
                        DashboardDescription: description,
                    },
                }}
            >
                <button className="button is-small is-info">
                    <FaEdit />
                </button>
            </Link>
            <Link
                to={{
                    pathname: '/delete',
                    deleteProps: {
                        id: value,
                        name: name,
                        itemType: 'dashboard',
                    },
                }}
            >
                <button className="button is-small is-info">
                    <FaTrashAlt />
                </button>
            </Link>
        </>
    )
}

const createColumns = () => [
    { Header: 'Dashboard Name', accessor: 'DashboardName', Cell: ({cell, row}) => (
        <Link to={`/${row.original.DashboardName}`}>
            {row.original.DashboardName}
        </Link>
    )},
    { Header: 'Description', accessor: 'DashboardDescription' },
    {
        Header: 'Manage',
        accessor: 'DashboardId',
        Cell: ({ cell, row }) => (
            <Cell
                name={row.original.DashboardName}
                description={row.original.DashboardDescription}
                value={cell.value}
            />
        ),
    },
]

export default () => {
    const { loading, dashboards } = useContext(DashboardContext)
    const data = dashboards ? dashboards : null

    if (loading || !dashboards) {
        return <LoadingSpinner />
    }

    const columns = useMemo(() => createColumns(), [])

    const tableData = useMemo(() => data, [data])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: tableData })

    return (
        <div className="adminTable">
            <table
                {...getTableProps()}
                className="table is-fullwidth is-striped"
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
