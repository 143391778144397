import React, { useContext, useEffect, useState } from 'react'
import EditForm from './EditForm'
import EditTable from './EditTable'
import { Link, withRouter } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import { CurrentDashboardContext } from '../../../wrappers/CurrentDashboardContext'
import AdminLayout from '../../../elem/admin/AdminLayout'

const EditDashboard = withRouter(({ history }) => {
    const { widgetGroups, currentDashboard, setNewObject } = useContext(
        CurrentDashboardContext
    )
    const [edited, setEdited] = useState(null)

    useEffect(() => {
        if (history && edited) {
            history.replace(`/admin/dashboard/${edited}`)
            setEdited(false)
        }
    }, [edited, history])

    const closeForm = (name) => {
        setEdited(name)
        setNewObject(name)
    }

    if (!currentDashboard) {
        return null
    }

    const pages = widgetGroups.map((page)=> ({...page, Widgets: page.Widgets.length}))

    return (
        <div className="editDashboard">
            <AdminLayout title={'Edit Dashboard'}>
                <EditForm dashboard={currentDashboard} closeForm={closeForm} />
                <hr />
                <EditTable pages={pages} name={edited} />
                <div className="has-text-centered">
                    <button className="button is-info">
                        <Link
                            className="has-text-white"
                            to={{
                                pathname: `/admin/dashboard/${
                                    edited
                                        ? edited
                                        : currentDashboard.DashboardName
                                }/page/create`,
                                pageProps: {
                                    dashboard: currentDashboard,
                                    widgets: widgetGroups,
                                },
                            }}
                        >
                            <div className="newDashboard">
                                New Page <FaPlus />
                            </div>
                        </Link>
                    </button>
                </div>
            </AdminLayout>
        </div>
    )
})

export default EditDashboard
