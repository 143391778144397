import React, { useRef, useMemo, useContext, useCallback } from 'react'
import { useFrame, useUpdate } from 'react-three-fiber'
import { Vector3, Color, Shape, Vector2, ArrowHelper, Vector4 } from 'three'
import { SceneContext } from './Scene'
import { generateMeshFromPointCloud } from './utils'
import { meshBasicMaterialProps, meshProps, scale } from '../../../../../utils/widgets/map/3d/config'

const Mesh = ({feature, centerPoint, style}) => {
    const shouldInteract = useRef(true)

    useFrame((state) => {
        const isControlled = state.scene.userData?.isControlled
        shouldInteract.current = !isControlled
    })

    const {
        tooltipContext: { updateTooltipState },
        legendState,
    } = useContext(SceneContext)
    const { geometry, styleData: featureStyles } = feature
    const { coordinates } = geometry

    const styleObj = useMemo(() => ({
        ...featureStyles,
        ...style,
    }), [featureStyles, style])

    const vertices = useMemo(() => {
        return generateMeshFromPointCloud(coordinates[0], centerPoint, scale)
    }, [coordinates, centerPoint])

    const visible = useMemo(() => legendState.find(
        (legendItem) => legendItem.layerName === feature.layerName
    ).visible, [legendState, feature])

    const geometryRef = useUpdate(geometry => {
        geometry.setFromPoints(vertices)
    }, [visible])

    const Meshes = useMemo(() =>
            <mesh
                {...meshProps}
                name={feature.layerName}
                userData={feature.tooltipData}
                onPointerEnter={(e) => {
                    if (shouldInteract.current) {
                        const data = e.eventObject.userData
                        const event = e.nativeEvent
                        const {
                            offsetX,
                            offsetY,
                            target,
                        } = event
                        updateTooltipState({
                            data,
                            target: {
                                offsetX,
                                offsetY,
                                scrollWidth: target.scrollWidth,
                                clientHeight:
                                    target.clientHeight,
                            },
                        })
                    }
                }}
                onPointerLeave={(e) => {
                    if (shouldInteract.current) {
                        e.eventObject.material.color = new Color(
                            styleObj.color ? styleObj.color : 'red'
                        )
                        if (visible) {
                            updateTooltipState(null)
                        }
                    }
                }}
            >
                <bufferGeometry
                    attach="geometry"
                    ref={geometryRef}
                />
                <meshBasicMaterial
                    {...meshBasicMaterialProps}
                    color={styleObj.color ? styleObj.color : 'red'}
                    opacity={styleObj.opacity ? styleObj.opacity : 0.3}
                />
            </mesh>
    , [shouldInteract, styleObj, updateTooltipState, feature])

    return (
        visible && (
            Meshes
        )
    )
}

const Polygon = ({ feature, centerPoint, style }) => {
    const { legendState } = useContext(SceneContext)
    const geometryRef = useRef()
    const { geometry } = feature
    const { coordinates } = geometry

    const shape = useMemo(() => {
        const vectors = coordinates
            ? coordinates[0].reduce((acc, pt) => {
                  if (pt.length > 1) {
                      return [
                          ...acc,
                          new Vector2(
                              (pt[0] - centerPoint.x) * scale.x,
                              (pt[1] - centerPoint.y) * scale.y
                          ),
                      ]
                  }
                  return acc
              }, [])
            : []
        return new Shape(vectors)
    }, [coordinates, centerPoint])

    if (!(feature.data && feature.data.Top && feature.data.Bottom)) {
        return null
    }

    // calculate height as the bottom - top
    const height = - Math.abs((feature.data.Top - feature.data.Bottom)) * scale.z

    // scale top
    const top = feature.data.Top * scale.z
    
    const visible = legendState.find(
        (legendItem) => legendItem.layerName === feature.layerName
    ).visible

    return (
        visible && (
            <mesh
                {...meshProps}
                name={feature.layerName}
                position={[0,top, 0]}
            >
                <extrudeBufferGeometry
                    attach="geometry"
                    ref={geometryRef}
                    args={[shape, {steps: 1, depth: height, bevelEnabled: false}]}
                />
                <meshBasicMaterial
                    {...meshBasicMaterialProps}
                    color={style.color ? style.color : 'red'}
                    opacity={style.opacity ? style.opacity : 0.3}
                />
            </mesh>
        )
    )
}

const Line = ({ feature, centerPoint, style: layerStyle, camera }) => {
    const shouldInteract = useRef(true)
    useFrame((state) => {
        const isControlled = state.scene.userData?.isControlled
        shouldInteract.current = !isControlled
    })
    const {
        tooltipContext: { updateTooltipState },
        sharedPageKey,
        sharedPageId,
        setSharedPageId,
        legendState,
    } = useContext(SceneContext)
    const { geometry, styleData: featureStyle } = feature
    const { coordinates } = geometry

    const style = useMemo(() => ({ ...layerStyle, ...featureStyle }), [layerStyle, featureStyle])

    const sections = useMemo(() => {
        let lastZValue = 0
        const vectors = coordinates.map((pt, idx) => {
            if (pt.length > 2) {
                return new Vector3(
                    (pt[0] - centerPoint.x) * scale.x,
                    (pt[1] - centerPoint.y) * scale.y,
                    (pt[2] - centerPoint.z) * scale.z
                )
            } else {
                // TODO: fix on sql side
                // if 2 pt wellbore, and there is no last z coordinate,
                // get the z value of the last pt
                if (idx > 0 && coordinates[idx - 1].length > 2) {
                    lastZValue = coordinates[idx - 1][2] - centerPoint.z
                }
                return new Vector3(
                    (pt[0] - centerPoint.x) * scale.x,
                    (pt[1] - centerPoint.y) * scale.y,
                    lastZValue * scale.z
                )
            }
        })
        const lineSegments = vectors.reduce((acc, curr, idx) => {
            if (idx <= vectors.length - 2) {
                const v1 = vectors[idx]
                const v2 = vectors[idx + 1]
                return [
                    ...acc,
                    {
                        v1,
                        v2,
                    },
                ]
            } else {
                return acc
            }
        }, [])

        const cylinders = lineSegments.map(({ v1, v2 }) => {
            const direction = new Vector3().subVectors(v2, v1)
            const arrow = new ArrowHelper(direction.clone().normalize(), v1)
            const rotation = arrow.rotation.clone()
            const position = new Vector3().addVectors(
                v1,
                direction.clone().multiplyScalar(0.5)
            )
            return {
                direction,
                rotation,
                position,
            }
        })
        return cylinders
    }, [coordinates, centerPoint])

    const getColor = useCallback((f, s) => {
        const c = new Color(s.color ? s.color : 'red')
        if (sharedPageKey && sharedPageId) {
            if (sharedPageId === f[sharedPageKey])
                return new Color('#ADD8E6')
        }
        return c
    }, [sharedPageKey, sharedPageId])

    const lineWidth = useMemo(() => style.width ? style.width * 0.02 : 0.1, [style])

    const material = useMemo(() => {
        return (
        <meshBasicMaterial
            {...meshBasicMaterialProps}
            color={getColor(feature, style)}
            opacity={style.opacity ? style.opacity : 0.3}
        />
    )}, [getColor, feature, style])

    const visible = useMemo(() => legendState.find(
        (legendItem) => legendItem.layerName === feature.layerName
    ).visible, [legendState, feature])

    const meshes = useMemo(() => {
        return sections.map(
                (
                    { direction, rotation, quaternion, position },
                    idx
                ) => (
                    <mesh
                        key={`${feature.layerName}-line-${idx}`}
                        castShadow={meshProps.castShadow}
                        userData={feature.tooltipData}
                        onPointerEnter={(e) => {
                            if (shouldInteract.current) {
                                const data = e.eventObject.userData
                                const event = e.nativeEvent
                                const {
                                    offsetX,
                                    offsetY,
                                    target,
                                } = event
                                e.eventObject.material.color = new Color(
                                    'black'
                                )
                                updateTooltipState({
                                    data,
                                    target: {
                                        offsetX,
                                        offsetY,
                                        scrollWidth: target.scrollWidth,
                                        clientHeight:
                                            target.clientHeight,
                                    },
                                })
                            }
                        }}
                        onPointerLeave={(e) => {
                            if (shouldInteract.current) {
                                e.eventObject.material.color = new Color(
                                    style.color ? style.color : 'red'
                                )
                                if (visible) {
                                    updateTooltipState(null)
                                }
                            }
                        }}
                        onClick={() => {
                            if (sharedPageKey) {
                                setSharedPageId(feature[sharedPageKey])
                            }
                        }}
                        rotation={[rotation.x, rotation.y, rotation.z]}
                        quaternion={quaternion}
                        position={position}
                        name={feature.layerName}
                    >
                        <cylinderBufferGeometry
                            args={[
                                lineWidth,
                                lineWidth,
                                direction.length(),
                                32,
                                1,
                                true,
                            ]}
                            attach={'geometry'}
                        />
                        {material}
                    </mesh>
                )
            )
        }
    , [material, sharedPageKey, setSharedPageId, shouldInteract, lineWidth, sections,  feature, style.color])

    const meshGroup = useMemo(() => 
        <group rotation={meshProps.rotation}>
            {meshes}
        </group>
    , [meshes])

    return (
        <>
            {material && sections && visible && (
                meshGroup
            )}
        </>
    )
}

export { Polygon, Line, Mesh }
