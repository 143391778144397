import ReactHtmlParser from 'react-html-parser'
const linkToHref = item => {
    const transformed = Object.keys(item).reduce((acc, curr) => {
        const value = item[curr]
        if (typeof value === 'string' && value.includes('</a>')) {
            // parse links and set to the csv syntax for hyperlinks
            try {
                const parsed = ReactHtmlParser(value)
                const csvString = `=HYPERLINK("${parsed[0].props.href}","${parsed[0].props.children[0]}")`
                return {
                    ...acc,
                    [curr]: csvString
                }
            } catch (e) {
                // when failing to parse the link, juts default to the og value
                return {
                    ...acc,
                    [curr]: value
                }
            }
        } else {
            // let all values that are not strings with </a> tags pass through uneffected
            return {
                ...acc,
                [curr]: value
            }
        }
    }, {})
    return transformed 
}
const transforms = [linkToHref]
export default transforms