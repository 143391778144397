import React, { useContext } from 'react'
import { WidgetDataContext } from '../../wrappers/WidgetDataContext'
import LoadingSpinner from '../LoadingSpinner'
import GroupBySelector from './shared/GroupBySelector'
import MaximizeButton from './shared/MaximizeButton'
import LegendButton from './shared/LegendButton'
import ExportButton from './shared/ExportButton'
import NoDataComponent from './NoDataComponent'
import { allowsOverflow } from '../../../utils/widgets/contentStyle'

export default ({ children }) => {
    const {
        widgetName: name,
        widgetIcon: icon,
        loading,
        widgetData: widget,
    } = useContext(WidgetDataContext)
    const widgetHasData = widget && widget.Data && widget.Data.length
    return (
        <div className="widgetWrapper">
            <div className="widgetHeaderWrapper">
                <div className="widgetHeader">
                    <div className="widgetNameWrapper">
                        <div className="icon">{icon}</div>
                        <div className="widgetName">{name}</div>
                    </div>
                    <div className="widgetButtonWrapper">
                        <ExportButton />
                        <MaximizeButton />
                    </div>
                </div>
                <div className="widgetHeaderRow2">
                    <GroupBySelector />
                    <LegendButton />
                </div>
            </div>
            <div className={`widgetContentWrapper ${!allowsOverflow(widget) ? 'overflow-disabled' : ''}`}>
                {loading ? (
                    <LoadingSpinner />
                ) : widgetHasData ? (
                    children
                ) : (
                    <NoDataComponent />
                )}
            </div>
        </div>
    )
}
