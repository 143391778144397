import React, { createContext, useState, useEffect, useMemo, useCallback, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getLayout, saveLayout, clearLayout } from '../../utils/localStorage/layouts'
import getLayouts from '../../utils/widgets/getLayouts'
import toast from '../elem/Toast'
import { APIRequestContext } from './APIRequestContext'
import withConfig from './withConfig'

const CurrentDashboardContext = createContext(null)

const CurrentDashboardContextProvider = ({ config, children }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [currentDashboard, setCurrentDashboard] = useState(null)
    const [pageLoading, setPageLoading] = useState(true)
    const [widgetGroups, setWidgetGroups] = useState([])
    const [currentWidgetGroup, setCurrentWidgetGroup] = useState({})
    const [editingLayout, setLayoutEditingState] = useState(false)
    const [layoutState, setLayoutState] = useState({})
    const location = useLocation()
    const {
        dashboardName,
        pageName: currentPage,
        detailKey,
        pageId,
        widgetId
    } = useParams()
    const [expandedWidgetId, setExpandedWidgetId] = useState(null)
    const [sharedPageKey, setSharedPageKey] = useState(null)
    const [sharedPageId, setSharedPageId] = useState(null)
    const [newObject, setNewObject] = useState(null)
    const { API_URL } = config // api url configured in public/config.<development|production>.js

    const defaultDateLabel = useMemo(() => currentWidgetGroup && currentWidgetGroup.DefaultDateRange ? currentWidgetGroup.DefaultDateRange : 'Last Year', [currentWidgetGroup])

    useEffect(() => {
        setPageLoading(true)
        authenticatedFetch(`${API_URL}/dashboard/${dashboardName}`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                const data = response.data
                setCurrentDashboard(data)
                const { Pages: widgetGroups } = data
                setWidgetGroups(widgetGroups)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                    'Widget Groups: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
                    })
                })
            .finally(() => setPageLoading(false))
        }, [API_URL, dashboardName, newObject])



    useEffect(() => {
        if (widgetGroups.length) {
            if (pageId) {
                const matchingWidgetGroup = widgetGroups.find(widgetGroup => widgetGroup.PageId === Number(pageId))
                setCurrentWidgetGroup(matchingWidgetGroup)
            } else {
                const location = currentPage ? `/${currentPage}` : widgetGroups[0].Location
                const matchingWidgetGroup = widgetGroups.find(
                    (widgetGroup) => widgetGroup.Location === location
                )
                setCurrentWidgetGroup(matchingWidgetGroup)
            }
        }
    }, [location, widgetGroups, currentPage, detailKey, pageId])

    
    // update shared key (if exists) and 
    // set shared page id to null on
    // when the currentWidgetGroup changes
    useEffect(() => {
        const updateLayout = async () => {
            setLayoutEditingState(false)
            if (currentWidgetGroup) {
                setSharedPageKey(currentWidgetGroup.SharedKey)
                setSharedPageId(null)
            }
            if(currentWidgetGroup && currentWidgetGroup.Widgets){
                const dbLayout = getLayouts(currentWidgetGroup.Widgets)
                const userLayout = await getLayout(currentWidgetGroup.PageId)
                if (userLayout) {
                    setLayoutState(userLayout)
                } else {
                    setLayoutState(dbLayout)
                }
            }
        }
        updateLayout()
    }, [currentWidgetGroup])

    useEffect(() => {
        if(currentDashboard && !currentDashboard.DashboardId){
            throw new Error("Dashboard Not Found")
        }
    }, [currentDashboard, location])

    const [ initialLayoutState, setInitialLayoutState ] = useState(layoutState)

    const startLayoutEdit = useCallback(() => {
        setInitialLayoutState(layoutState)
        setLayoutEditingState(true)
    }, [setLayoutEditingState, setInitialLayoutState, layoutState])

    const saveLayoutState = useCallback(() => {
        const { PageId } = currentWidgetGroup
        setLayoutEditingState(false)
        saveLayout(PageId, layoutState)
    }, [currentWidgetGroup, layoutState])

    const cancelLayoutEdit = useCallback(() => {
        setLayoutEditingState(false)
        setLayoutState(initialLayoutState)
    }, [initialLayoutState, setLayoutState, setLayoutEditingState])

    const resetLayoutState = useCallback(() => {
        const { PageId, Widgets } = currentWidgetGroup
        const dbLayout = getLayouts(Widgets)
        clearLayout(PageId)
        setLayoutState(dbLayout)
        setLayoutEditingState(false)
    }, [currentWidgetGroup])
    
    return (
        <CurrentDashboardContext.Provider
            value={{
                dashboardName,
                currentPage,
                pageId,
                widgetId,
                currentWidgetGroup,
                detailKey,
                widgetGroups,
                currentDashboard,
                editingLayout,
                setLayoutEditingState,
                startLayoutEdit,
                cancelLayoutEdit,
                resetLayoutState,
                saveLayoutState,
                layoutState,
                setLayoutState,
                expandedWidgetId,
                setExpandedWidgetId,
                sharedPageKey,
                setSharedPageKey,
                sharedPageId,
                setSharedPageId,
                setNewObject,
                defaultDateLabel,
                pageLoading
            }}
        >
            {children}
        </CurrentDashboardContext.Provider>
    )
}

export { CurrentDashboardContext }
export default withConfig(CurrentDashboardContextProvider)
